import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Terms from "../views/Terms.vue";
import Profile from "../views/Profile.vue";

import Console from "../views/console/Console.vue";
import AddEditMedia from "../views/console/AddEditMedia.vue";

import ManageOrganisation from "../views/manage/ManageOrganisation";
import ManageChannel from "../views/manage/ManageChannel";
import ManageCatalog from "../views/manage/ManageCatalog";
import ManageMedia from "../views/manage/ManageMedia";

import Organisation from "../views/Organisation.vue";
import GraphicOverlay from "../views/GraphicOverlay.vue";

import Viewer from "../views/Viewer.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/terms",
    name: "Privacy & Terms",
    component: Terms,
    beforeEnter: scrollToTop,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    path: "/console",
    name: "Console",
    component: Console,
    beforeEnter: [authGuard, adminGuard],
  },
  {
    path: "/console/media/:mediaID",
    name: "Edit Media",
    component: AddEditMedia,
    beforeEnter: [authGuard, adminGuard],
  },
  {
    path: "/manage/organisation/:orgID",
    name: "Manage Organisation",
    component: ManageOrganisation,
    beforeEnter: [authGuard, checkManagementGuard],
  },
  {
    path: "/manage/channel/:channelID",
    name: "Manage Channel",
    component: ManageChannel,
    beforeEnter: [authGuard, checkManagementGuard],
  },
  {
    path: "/manage/catalog/:catalogID",
    name: "Manage Catalog",
    component: ManageCatalog,
    beforeEnter: [authGuard, checkManagementGuard],
  },
  {
    path: "/manage/media/:mediaID",
    name: "Manage Media",
    component: ManageMedia,
    beforeEnter: [authGuard, checkManagementGuard],
  },
  {
    path: "/view/:mediaID",
    name: "Watching!",
    component: Viewer,
    beforeEnter: authGuard,
  },
  {
    path: "/view/:mediaID/overlay",
    name: "Overlay",
    props: {
      hideFrame: true,
    },
    component: GraphicOverlay,
  },
  {
    path: "/:orgID",
    name: "Organisation",
    component: Organisation,
    beforeEnter: [authGuard, scrollToTop],
  },
  // {
  //   path: "/terms",
  //   name: "Privacy & Terms",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

function checkManagementGuard(to, from, next) {
  if (window.$sso.authenticated) {
    let ssoClientID = process.env.VUE_APP_SSO_CLIENT;
    let isClient = false;
    let isAdmin = false;
    if (window.$sso.resourceAccess[ssoClientID]) {
      isClient =
        window.$sso.resourceAccess[ssoClientID].roles.includes("client") ??
        false;
      isAdmin =
        window.$sso.resourceAccess[ssoClientID].roles.includes("admin") ??
        false;
    }
    if (isClient || isAdmin) {
      next();
    } else {
      next("/");
    }
  } else {
    next("/");
  }
}

function authGuard(to, from, next) {
  if (window.$sso.authenticated) {
    next();
  } else {
    const redirect = window.location.href;
    const url = redirect.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
    window.location.href = window.$sso.createLoginUrl({ redirectUri: url });
    // let nextPath = to.fullPath;
    // window.location.href = window.$sso.createLoginUrl({
    //   redirectUri: nextPath,
    // });
  }
}

function adminGuard(to, from, next) {
  if (window.$sso.authenticated) {
    let ssoClientID = process.env.VUE_APP_SSO_CLIENT;
    let isAdmin = false;
    if (window.$sso.resourceAccess[ssoClientID]) {
      isAdmin =
        window.$sso.resourceAccess[ssoClientID].roles.includes("admin") ??
        false;
    }
    if (isAdmin) {
      next();
    } else {
      next("/");
    }
  } else {
    next("/");
  }
}

function scrollToTop() {
  window.scrollTo(0, 0);
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
