<template>
  <div
    id="home"
    class="container-fluid overflow-hidden primary-catalog-display"
  >
    <!-- Hero Images - Global -->
    <hero-carousel
      :heroObjects="featuredItemsCarousel"
      carouselID="heroCarousel"
      :interval="5000"
    ></hero-carousel>
    <!-- Live and Upcoming - Global -->
    <catalog-row
      :catalogid="'liveAndUpcoming'"
      :catalog-name="'Live and Upcoming'"
      :catalog-objects="liveAndUpcomingMedia"
      sort-order="asc"
      :show-unpublished="false"
    ></catalog-row>

    <!-- Global Highlighted Catalogs -->
    <catalog-row
      v-for="(catalog, index) in globalFeaturedCatalogs"
      :key="index"
      :catalogid="catalog.catalogID"
      :catalog-name="catalog.catalogName"
      :catalog-subtitle="catalog.subtitle"
      :catalog-objects="catalog.media"
      sort-order="desc"
      :show-unpublished="false"
    ></catalog-row>

    <!-- Premium Channels -->
    <div v-for="(organisation, index) in publishedOrganisations" :key="index">
      <ChannelHeader
        :channelName="organisation.organisationName"
        :channelID="organisation.vanityUrl"
        :linkable="true"
      ></ChannelHeader>

      <hero-carousel
        v-if="typeof organisation.featured != 'undefined'"
        :heroObjects="organisation.featured"
        :carouselID="'car-' + organisation.organisationID"
        :interval="5000"
      ></hero-carousel>

      <!-- Standard Channel catalogs -->
      <div
        v-for="(channel, index) in filterVisibleChannels(organisation.channels)"
        :key="index"
      >
        <catalog-row
          v-for="(catalog, index) in channel.catalogs"
          :key="index"
          :catalogid="catalog.catalogID"
          :catalog-name="catalog.catalogName"
          :catalog-objects="catalog.media"
          sort-order="desc"
          :show-unpublished="false"
        ></catalog-row>
      </div>
    </div>

    <div class="spacer"></div>
  </div>
</template>

<script>
import HeroCarousel from "../components/HeroCarousel.vue";
import ChannelHeader from "../components/ChannelHeader.vue";
import CatalogRow from "../components/CatalogRow.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    HeroCarousel,
    ChannelHeader,
    CatalogRow,
  },
  props: {},
  computed: {
    ...mapState({
      featuredItemsCarousel: (state) => state.featuredItemsCarousel,
      liveAndUpcomingMedia: (state) => state.liveAndUpcomingMedia,
      organisations: (state) => state.organisations,
      catalogs: (state) => state.catalogs,
    }),
    globalFeaturedCatalogs() {
      return this.catalogs.filter((catalog) => catalog.isGlobalHomeCatalog);
    },
    publishedOrganisations() {
      return this.organisations.filter(
        (organisation) => organisation.isPublished
      );
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("setPageTitle", "");
  },
  methods: {
    filterVisibleChannels(channels) {
      return channels.filter((c) => c.showOnHome);
    },
  },
  watch: {},
};
</script>

<style scoped>
#home .spacer {
  height: 30px;
}
</style>
