<template>
  <div
    class="tab-pane fade"
    :class="{ active: isActive, show: isActive }"
    :id="id + '-tab'"
    role="tabpanel"
    :aria-labelledby="id + '-tab'"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PaneItem",
  components: {},
  props: ["id", "isActive"],
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
