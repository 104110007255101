<template>
  <div v-if="visible" class="row">
    <div class="col">
      <div class="card mb-3">
        <div class="card-body text-center my-5">
          <h1>
            <fa :icon="['fad', icon]" class="fa-spin mb-3"></fa>
          </h1>
          <h3>{{ message }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingDiv",
  components: {},
  props: ["visible", "icon", "message"],
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
