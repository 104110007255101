<template>
  <div class="console-main" :class="width + ' ' + animationClasses">
    <div class="row">
      <div class="col-12 pt-4">
        <div class="tab-content" id="tabsPane">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pane",
  components: {},
  props: {
    width: {
      type: String,
      default: "col-10",
    },
    animate: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: String,
      default: "animate__fadeInRight",
    },
  },
  computed: {
    animationClasses() {
      if (this.animate) {
        return "animate__animated " + this.animation;
      }
      return "";
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
