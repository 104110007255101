<template>
  <nav
    class="navbar navbar-expand-md navbar-dark bg-light fixed-top py-0 d-flex"
  >
    <div class="container-fluid">
      <router-link class="nav-link py-1" to="/">
        <img
          class="al-navbar-logo px-2"
          src="../assets/logo.svg"
          alt="Anywhere Live"
        />
      </router-link>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-auto">
          <!-- Blank space here -->
        </ul>

        <ul class="navbar-nav al-nav-item">
          <li class="nav-item align-self-center pe-3">
            <a
              class="btn btn-primary text-white px-3 navbar-button-fixed"
              href="https://greatbigevents.hubspotpagebuilder.com/anywhere-live-0"
            >
              Stream with Us!
            </a>
          </li>

          <UserNavButton></UserNavButton>
        </ul>
      </div>
    </div>
  </nav>
  <div class="navbar-accent w-100 fixed-top"></div>
</template>

<script>
import UserNavButton from "./UserNavButton.vue";

export default {
  name: "Navbar",
  components: {
    UserNavButton,
  },
};
</script>

<style lang="scss" scoped></style>
