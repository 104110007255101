<template>
  <div class="row org-banner" :style="{ background: organisationColour }">
    <div v-if="!logoURL" class="banner-text">
      {{ organisationName }}
    </div>
    <img
      v-if="logoURL"
      class="banner-logo py-2"
      :src="logoURL"
      :alt="organisationName"
    />
  </div>
</template>

<script>
export default {
  name: "OrganisationBanner",
  components: {},
  props: ["organisationName", "logoURL", "organisationColour"],
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
