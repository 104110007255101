<template>
  <div id="terms" class="container-fluid">
    <div class="row pt-4 pb-5">
      <div class="col-2"></div>
      <div class="col-8">
        <div class="card">
          <div class="pt-3 card-header text-center text-primary">
            <h4>ANYWHERE LIVE TERMS OF USE & PRIVACY POLICY</h4>
          </div>
          <div class="card-body pb-1">
            <div class="card-text text-dark">
              <h5>1. Introduction</h5>
              <p>
                These Anywhere Live (“AL”) Terms of Use and Privacy Policy
                (“Terms”) apply to your use of the online streaming platform
                (“AL Stream”), being a real time live stream service of an
                event, sport competition, conference or similar (“Event”). For
                the avoidance of doubt, the AL Stream includes the website and
                any other related online applications relevant to the streaming
                service. For purposes hereof:
              </p>
              <ul>
                <li>
                  “RWS Global Sports”, “we” or “us” means Great Big Events Pty Limited, a
                  proprietary company registered in Australia with ABN 35 067
                  901 866;
                </li>
                <li>
                  “Event Partners” means the Event Organiser, our Client and/or
                  any rights holders in respect of the Event; and
                </li>
                <li>“you” means each person who uses the AL Stream.</li>
              </ul>
              <p>
                You acknowledge and agree that you have read and accept these
                Terms when you register with us to create an account. If you do
                not agree to these Terms, you may not use the AL Stream.
              </p>

              <h5>2. AL Stream</h5>
              <p>
                By using the AL Stream, you acknowledge and agree to us
                gathering personal information about you and your use of the
                streaming service to view our live events. Global intellectual
                property rights relating to the AL Stream are held by RWS Global Sports and
                may be exclusively licensed to the Event Partners of a specific
                Event. You may not use, adapt, vary, copy, stream, record or
                modify the content in any way. RWS Global Sports and/or the Event Partners, as
                the case may be, may stop streaming or cut your access at any
                time without any notice to you in their sole and absolute
                discretion.
              </p>

              <h5>3. Use of the AL Service</h5>
              You are responsible for your use of the AL Service. You agree
              that:
              <ul>
                <li>
                  your use of the AL Service is entirely at your own risk;
                </li>
                <li>
                  you will not use any of the content for any commercial
                  purpose;
                </li>
                <li>
                  you will not use the content to bully, intimidate, harass or
                  disparage any person whose image is discernible in the AL
                  Stream;
                </li>
                <li>
                  you will immediately notify RWS Global Sports of any content that is
                  defamatory, hateful, threatening, or pornographic, or which
                  incites violence; and
                </li>
                <li>
                  you will not use the AL Service in any manner that violates or
                  infringes someone else’s rights or privacy.
                </li>
              </ul>

              <h5>4. How We Use Your Data</h5>
              <p>
                You hereby warrant to us that all the information you provide to
                us during the sign up process will be true and correct.
              </p>
              <p>
                We collect information about you such as: your full name, e-mail
                address, post code, mobile number, your interests, preferences
                and any other information you may choose to provide to us
                (“Personal Data”). The Personal Data collected from you will be
                stored together with your log-in details to form your AL
                account.
              </p>
              You hereby consent that:
              <ul>
                <li>We may collect the Personal Data;</li>
                <li>
                  We may share the Personal Data with the Event Partners, our
                  contractors or other third parties.
                </li>
                <li>
                  We, the Event Partners, our contractors or other third parties
                  may use your Personal Data to communicate with you and to send
                  you information about similar upcoming events and/or
                  promotions. Should you no longer wish to receive such
                  communications you can opt-out at any time by following the
                  opt-out instructions contained in the relevant communication.
                </li>
                <li>
                  We may add information to your profile that we receive from
                  the Event Partners or which we collect by virtue of your use
                  of the AL Stream or any other related service or application
                  that is owned or licensed by us.
                </li>
                <li>
                  We may use your Personal Data to enable you to integrate your
                  information with your social media accounts and to improve
                  your user experience of the AL platform.
                </li>
                <li>
                  We may use your Personal Data to compile non-personally
                  identifiable and/or aggregated statistics about your
                  preferences, choices, use of services and products and we may
                  share such aggregated information with third parties who may
                  be located in different jurisdictions including overseas;
                </li>
                <li>
                  We may share your Personal Data with other third parties to
                  the extent that we believe in good faith that such disclosure
                  is reasonably necessary to meet any applicable law,
                  regulation, legal process or enforceable governmental request
                  or to protect us, our users or the public against harm to
                  their respective rights, property or safety, as required or
                  permitted by law; and
                </li>
                <li>
                  In the event that we are the subject of a reorganisation,
                  merger, or sale we may transfer any and all Personal Data we
                  have collected to the relevant third party. In such event your
                  personal information will continue to be protected.
                </li>
              </ul>

              <a id="privacy"><h5>5. Privacy Policy</h5></a>
              <p>
                Your participation in our AL Stream and any Personal Data you
                give us will be kept in confidence and will only be passed on to
                any third parties with your consent or as described in these
                Terms. Collected information may include or reflect personal
                information that could identify you, as well as non-personal
                information.
              </p>
              We may use your data for the following purposes:
              <ul>
                <li>Identification and authentication;</li>
                <li>Operating and providing our services;</li>
                <li>Communicating with you;</li>
                <li>Improving our services;</li>
                <li>Customising your experience;</li>
                <li>Marketing and advertising;</li>
                <li>Legal compliance.</li>
              </ul>
              Whenever we process your data for one of these purposes, we have
              determined that one or more of the following lawful bases apply:
              <ol>
                <li>Performance of a contract;</li>
                <li>Legitimate commercial interest;</li>
                <li>Compliance with a legal obligation; or</li>
                <li>Consent.</li>
              </ol>
              <p>
                We will do our best to make sure your information is up to date
                and accurate, and we may from time to time ask you to confirm
                your information. You may at any stage ask us to correct your
                information if there are any errors.
              </p>
              <p>
                Should you wish for us to stop processing your Personal Data,
                you may email your request to dpo@rwsglobal.com. Upon
                receiving your written request we shall de-activate your AL
                account within 5 (five) working days. We shall no longer process
                any of your Personal Data.
              </p>
              <p>
                We do not knowingly collect personal information from
                individuals who are under 18 years of age. You must be at least
                18 years of age to use our services. Individuals under the
                applicable age may use our services only through a parent or
                legal guardian's account and with their involvement. If you are
                a parent or legal guardian who believes your child has provided
                personal information to RWS Gloabl Sports without your consent, you may ask us
                to delete such information by contacting us at
                dpo@rwsglobal.com.
              </p>
              <p>
                For more information regarding our company’s general Privacy
                Policy please email your request to us at the email address set
                out above.
              </p>

              <h5>6. Limitation of Liability and Indemnity</h5>
              <p>
                You agree that under no circumstances will we or the Event
                Partner be responsible or liable to you or any other person or
                entity for any direct, indirect, incidental, consequential or
                other damages (including, but not limited to, damages for loss
                of profits, goodwill, use, data or other intangible losses)
                arising out of or relating in any way to the use of the AL
                Service, the unauthorised access to or alteration of your stream
                or data, the information or content available on or through the
                AL Service.
              </p>
              <p>
                The potential liability to you is hereby limited to the fullest
                extent possible under applicable law.
              </p>
              <p>
                You hereby agree to indemnify, defend and hold us, our
                affiliates, related companies, event partners, officers,
                directors, agents, licensors and any other contractors and
                employees harmless from any and all claims, losses, expenses,
                damages, actions, demands, liabilities or costs, including but
                not limited to legal fees resulting out of or your use of the AL
                Platform, including without limitation:
              </p>
              <ol type="a">
                <li>
                  Any harm or loss suffered by you as a result of your use of
                  the platform and/or the other related services/applications
                  provided by us; and
                </li>
                <li>Any violation of these Terms by you.</li>
              </ol>

              <h5>7. General</h5>
              <p>
                If any portion of these Terms is found to be unenforceable, the
                remaining portions will remain in full force and effect.
              </p>
              <p>
                If we fail to enforce any of these Terms, it will not be
                considered a waiver.
              </p>
              <p>
                Notwithstanding the foregoing, we reserve the right to amend
                these Terms at any time, with new or amended terms coming into
                force on the date of publication on our website or platform,
                although we will take all reasonable steps necessary to inform
                you of those changes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  mounted() {
    this.$store.commit("setPageTitle", "Privacy & Terms");
  },
};
</script>

<style scoped></style>
