<template>
  <span
    :class="
      'console h-100 d-flex align-content-center text-right flex-wrap ' +
      columnType +
      isEnabled +
      grow
    "
  >
    <fa
      v-if="icon"
      :icon="['fad', icon]"
      :class="{ 'ps-3': !nopadding }"
      class="icon console edit-btn"
    />
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "PaneCardItemButton",
  components: {},
  props: ["enabled", "icon", "type", "nopadding", "cangrow"],
  computed: {
    columnType() {
      if (this.type === "add") {
        return " add-column ";
      } else if (this.type === "delete") {
        return " del-column ";
      }
      return " edit-column ";
    },
    isEnabled() {
      if (this.enabled) {
        return "";
      }
      return "disabled";
    },
    grow() {
      if (this.cangrow) {
        return " grow ";
      }
      return "";
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
