<template>
  <pane-input
    panelID="panel-input"
    :title="modal.title"
    :icon="modal.icon"
    v-model:modal="modal.inputs"
    v-model:files="modal.files"
    @close="clickModalClose()"
    @save="clickModalSave()"
  />

  <div id="manage" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="general"
          caption="General"
          icon="photo-film-music"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="users"
          caption="Users"
          icon="users"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="score"
          caption="Scoring"
          icon="whistle"
          :isActive="false"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- Organisation Pane -->
        <pane-item id="general" :isActive="true">
          <pane-card title="Media Details" icon="square-info">
            <div class="row">
              <div class="col-2 ps-4">
                <div class="row">
                  <pane-card-body>
                    <img
                      class="card thumb-preview"
                      :src="media.thumbnailURL"
                      :alt="media.mediaName"
                    />
                  </pane-card-body>
                </div>
                <div class="row">
                  <pane-card-body>
                    <img
                      class="card thumb-preview"
                      :src="media.heroImageURL"
                      :alt="media.mediaName"
                    />
                  </pane-card-body>
                </div>
              </div>
              <div class="col-10">
                <pane-card-body>
                  <pane-card-item caption="Media ID" :value="mediaID">
                    <pane-card-item-button
                      icon="pen-slash"
                      :enabled="false"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item caption="Name" :value="media.mediaName">
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'Text',
                              key: 'mediaName',
                              value: media.mediaName,
                            },
                          ],
                          'Media Name',
                          'input-text'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Venue"
                    :value="media.mediaShortDescription"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'Text',
                              key: 'mediaShortDescription',
                              value: media.mediaShortDescription,
                            },
                          ],
                          'Venue',
                          'goal-net'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item caption="Visibility" :value="published">
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: 'Publish?',
                              type: 'Check',
                              key: 'isPublished',
                              value: boolToCheck(media.isPublished),
                            },
                          ],
                          'Visibility',
                          'megaphone'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
                <pane-card-body>
                  <pane-card-item
                    caption="Stream Scheduled Start"
                    :value="formatDate(media.startDateUTC)"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption:
                                'Note: Times are in your local timezone.',
                              type: 'Date',
                              key: 'startDateUTC',
                              value: dateToLocal(media.startDateUTC),
                            },
                          ],
                          'Stream Scheduled Start',
                          'calendar'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Stream Scheduled End"
                    :value="formatDate(media.endDateUTC)"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption:
                                'Note: Times are in your local timezone.',
                              type: 'Date',
                              key: 'endDateUTC',
                              value: dateToLocal(media.endDateUTC),
                            },
                          ],
                          'Stream Scheduled End',
                          'calendar'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
                <pane-card-body>
                  <pane-card-item
                    caption="Thumbnail"
                    :value="media.thumbnailURL"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'File',
                              key: 'thumbnailURL',
                              s3key: 'media/' + mediaID + '/assets',
                            },
                          ],
                          'Thumbnail',
                          'image-polaroid'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Hero Image"
                    :value="media.heroImageURL"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'File',
                              key: 'heroImageURL',
                              s3key: 'media/' + mediaID + '/assets',
                            },
                          ],
                          'Hero Image',
                          'image-polaroid'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
              </div>
            </div>
            <div class="row px-4 pb-3">
              <div
                class="description-box d-flex p-0 justify-content-between align-items-center"
              >
                <p class="p-2 flex-grow-1" v-html="media.mediaDescription"></p>
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: 'Note: HTML Tags can be used',
                          type: 'Textarea',
                          key: 'mediaDescription',
                          value: media.mediaDescription,
                        },
                      ],
                      'Description',
                      'comments'
                    )
                  "
                  class="ps-0"
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                  :nopadding="true"
                ></pane-card-item-button>
              </div>
            </div>
          </pane-card>
          <pane-card title="Stream Control" icon="satellite">
            <pane-card-body>
              <pane-card-item
                caption="Stream URL"
                :value="'https://anywherelive.tv/view/' + mediaID"
              >
                <pane-card-item-button
                  @click="$router.push('/view/' + mediaID)"
                  icon="link"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item caption="Control" :value="streamControlMessage">
                <pane-card-item-button
                  v-if="streamControlState === 1"
                  @click="clickStateGoLive"
                  icon="wifi"
                  :enabled="true"
                  type="add"
                  :cangrow="true"
                  ><span class="px-3">Go Live!</span></pane-card-item-button
                >
                <pane-card-item-button
                  v-if="streamControlState === 2"
                  @click="clickStateStopStream"
                  icon="octagon"
                  :enabled="true"
                  type="delete"
                  :cangrow="true"
                  ><span class="px-3">Stop Stream!</span></pane-card-item-button
                >
                <pane-card-item-button
                  v-if="streamControlState >= 3"
                  icon="wifi-slash"
                  :enabled="false"
                  type="edit"
                  :cangrow="true"
                  ><span class="px-3">Not ready...</span></pane-card-item-button
                >
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Users Pane -->
        <pane-item id="users" :isActive="false">
          <pane-card title="Media Managers" icon="lock">
            <pane-card-body>
              <pane-card-item
                v-for="(user, index) in media.admins"
                :key="index"
                :caption="user.firstName + ' ' + user.lastName"
                :value="user.email"
              >
                <pane-card-item-button
                  @click="clickDeleteUser(index)"
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Scoring Pane -->
        <pane-item id="score" :isActive="false">
          <pane-card title="Scoring Control" icon="tv-retro">
            <pane-card-body>
              <div v-if="!media.scoringEnabled" class="row">
                <div class="col-12 text-center">
                  <div class="alert alert-warning m-3 pb-1 pt-3" role="alert">
                    <h5>
                      <p>The scoring feature is not enabled for this stream.</p>
                      <p>
                        To find out more, or to enable real-time scoring,
                        contact your Anywhere Live account manager.
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
              <scoring-generic
                v-if="media.scoringEnabled"
                :mediaID="mediaID"
                :mediaName="media.mediaName"
              ></scoring-generic>
            </pane-card-body>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";

import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";

import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";

import PaneInput from "@/components/ConsolePane/PaneInput";

import ScoringGeneric from "@/components/Scoring/ScoringGeneric";

import moment from "moment";

export default {
  name: "ManageMedia",
  components: {
    PaneSidebar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
    PaneInput,
    ScoringGeneric,
  },
  props: {},
  computed: {
    mediaID() {
      return this.$route.params.mediaID;
    },
    media() {
      return this.$store.getters.findMediaByID(this.mediaID);
    },
    dataReady() {
      return typeof this.media !== "undefined";
    },
    published() {
      if (this.media.isPublished) {
        return "Published";
      } else {
        return "Hidden";
      }
    },
    streamControlState() {
      switch (this.media.cloudFormationStatus) {
        case 0: // cloud formation hasn't executed
          return 3;
        case 1: // cloud formation queued to execute
          return 3;
        case 2: // cloud formation executing
          return 3;
        case 4: // cloud formation ready
          if (this.media.mediaLiveStatus === 1) {
            // media live channel hasn't started
            return 3;
          } else if (this.media.mediaLiveStatus === 2) {
            // media live channel has started
            if (this.media.isLive) {
              return 2;
            } else {
              return 1;
            }
          } else if (
            this.media.mediaLiveStatus === 4 ||
            this.media.mediaLiveStatus === 5
          ) {
            // media live channel is stopping/stopped
            return 5;
          } else {
            // media live channel failed to start
            return -1;
          }
        case 5:
          // cloud formation is deleting
          return 5;
        case 6:
          // cloud formation is deleted
          return 5;
        default:
          // cloud formation failed to execute
          return -1;
      }
    },
    streamControlMessage() {
      switch (this.streamControlState) {
        case 1:
          return "Ready to stream...";
        case 2:
          return "Stream in progress...";
        case 3:
          return "Stream not ready...";
        case 5:
          return "Stream has ended...";
        default:
          return "An error has occurred. Contact Anywhere Live support.";
      }
    },
  },
  data() {
    return {
      modal: [],
    };
  },
  methods: {
    boolToCheck(v) {
      if (v) {
        return true;
      }
      return 0;
    },
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
    dateToLocal: function (date) {
      return moment(date + "+00:00")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
    },
    dateToUTC: function (date) {
      return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
    },
    clickStateGoLive() {
      this.$swal({
        title: "Are you ready to GO LIVE?",
        showCancelButton: true,
        confirmButtonText: "GO LIVE",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("setMediaStreamState", {
              mediaID: this.media.mediaID,
              streamAction: "golive",
            })
            .then(
              (res) => {
                if (res.status) {
                  this.$swal({
                    title: "Stream is LIVE",
                    icon: "success",
                  });
                  this.media.isLive = true;
                } else {
                  this.$swal({
                    showCancelButton: true,
                    showConfirmButton: false,
                    title: "Error!",
                    text: res.result,
                    icon: "error",
                  });
                }
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.result,
                  icon: "error",
                });
              }
            );
        }
      });
    },
    clickStateStopStream() {
      this.$swal({
        title: "Are you ready to STOP STREAMING?",
        showCancelButton: true,
        confirmButtonText: "STOP",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("setMediaStreamState", {
              mediaID: this.media.mediaID,
              streamAction: "endstream",
            })
            .then(
              (res) => {
                if (res.status) {
                  this.$swal({
                    title: "Stream has ENDED",
                    icon: "success",
                  });
                  this.media.isLive = false;
                  this.media.cloudFormationStatus = 5;
                } else {
                  this.$swal({
                    showCancelButton: true,
                    showConfirmButton: false,
                    title: "Error!",
                    text: res.result,
                    icon: "error",
                  });
                }
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.result,
                  icon: "error",
                });
              }
            );
        }
      });
    },
    clickDeleteUser(index) {
      let userID = this.media.admins[index].uuid;
      let email = this.media.admins[index].email;
      let adminsArray = this.media.managementUserUUIDs.split(",");

      this.$swal({
        title: "Are you sure you want to remove " + email + "?",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.isConfirmed) {
          // remove userID from adminsArray
          let i = adminsArray.indexOf(userID);
          if (i !== -1) {
            adminsArray.splice(i, 1);
          }
          let formData = new FormData();
          formData.append("managementUserUUIDs", adminsArray.join(","));
          this.$store
            .dispatch("setMediaField", {
              mediaID: this.media.mediaID,
              data: formData,
            })
            .then(
              () => {
                this.$swal({
                  title: "Success!",
                  text: "User '" + email + "' was removed.",
                  icon: "success",
                  confirmButtonText: "Yay!",
                });
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.error,
                  icon: "error",
                  cancelButtonText: "Oh no!",
                });
              }
            );

          // remove local instance
          this.media.admins.splice(index, 1);
        }
      });
    },
    clickModalActivate: function (modal, title, icon) {
      this.modal.title = title;
      this.modal.icon = icon;
      this.modal.inputs = modal;
      this.modal.files = [];
      this.$modal.show("panel-input");
    },
    clickModalClose: function () {
      this.$modal.hideAll();
    },
    clickModalSave: function () {
      let formData = new FormData();

      // process files
      this.modal.files.forEach((f, i) => {
        formData.append("file", f.currentImage);
        formData.append("FILES_" + i + "_S3KEY", f.s3key);
        formData.append("FILES_" + i + "_DBKEY", f.dbkey);
      });

      // process fields
      this.modal.inputs.forEach((i) => {
        if (i.type === "Date") {
          // convert datetime to suitable SQL format
          let value = this.dateToUTC(i.value);
          this.media[i.key] = value;
          formData.append(i.key, value);
        } else if (i.type === "File") {
          console.log("file");
        } else if (i.type === "Check") {
          let value = 0;
          if (i.value === true) {
            value = 1;
          }
          this.media[i.key] = value;
          formData.append(i.key, value);
        } else {
          this.media[i.key] = i.value;
          formData.append(i.key, i.value);
        }
      });

      // commit data
      this.$store
        .dispatch("setMediaField", {
          mediaID: this.media.mediaID,
          data: formData,
        })
        .then(
          (res) => {
            // update local model with S3 response
            res.filekeys.forEach((f) => {
              this.media[f.key] = f.s3url;
            });

            this.$swal({
              title: "Success!",
              text: res.result,
              icon: "success",
              confirmButtonText: "Yay!",
            });
          },
          (error) => {
            this.$swal({
              showCancelButton: true,
              showConfirmButton: false,
              title: "Error!",
              text: error.error,
              icon: "error",
              cancelButtonText: "Oh no!",
            });
          }
        );
      this.clickModalClose();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", "Manage Media");
    this.$store.dispatch("getMediaAdminUsers", {
      mediaID: this.mediaID,
    });
  },
};
</script>

<style lang="scss" scoped></style>
