<template>
  <overlay-generic
      :mediaID="mediaID"
      bugImg="https://awl-assets-production.s3.ap-southeast-2.amazonaws.com/default_bug.png"
      bugPos="right"
  />
</template>

<script>
import OverlayGeneric from "@/components/Scoring/OverlayGeneric";

export default {
  name: "GraphicOverlay",
  components: {
    OverlayGeneric,
  },
  props: ["hideFrame"],
  computed: {
    mediaID() {
      return this.$route.params.mediaID;
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
