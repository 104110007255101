<template>
  <div id="console" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="organisation"
          caption="Organisation"
          icon="sitemap"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          @click="initUsersPane"
          id="users"
          caption="Users"
          icon="users"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          @click="initMediaPane"
          id="media"
          caption="Media"
          icon="tv-retro"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          @click="initHardwarePane"
          id="hardware"
          caption="Hardware"
          icon="computer-classic"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          @click="initWorkerPane"
          id="workers"
          caption="Workers"
          icon="robot"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="cloud"
          caption="Cloud"
          icon="clouds"
          :isActive="false"
          :disabled="true"
          pill="Coming Soon"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="search"
          caption="Search"
          icon="telescope"
          :isActive="false"
          :disabled="true"
          pill="Coming Soon"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- Organisation Pane -->
        <pane-item id="organisation" :isActive="true">
          <pane-card>
            <tabs-topbar>
              <pane-sidebar-item
                id="tab-general"
                caption="General"
                :isActive="true"
              ></pane-sidebar-item>
              <pane-sidebar-item
                id="tab-config"
                caption="Global Configuration"
                :isActive="false"
              ></pane-sidebar-item>
              <pane-sidebar-item
                id="tab-landing"
                caption="Landing Page"
                :isActive="false"
              ></pane-sidebar-item>
              <pane-sidebar-item
                id="tab-diagnostics"
                caption="Diagnostics"
                :isActive="false"
              ></pane-sidebar-item>
            </tabs-topbar>

            <pane width="col-12" :animate="false">
              <!-- General Tab -->
              <pane-item id="tab-general" :isActive="true">
                <pane-card title="System Details" icon="browser">
                  <pane-card-body>
                    <pane-card-item
                      caption="Application Version"
                      :value="system.backend.version.application"
                    >
                    </pane-card-item>
                    <pane-card-item
                      caption="Environment"
                      :value="
                        frontendEnvironment + ' | ' + system.backend.environment
                      "
                    >
                    </pane-card-item>
                    <pane-card-item
                      caption="Database Model"
                      :value="system.backend.version.database"
                    >
                    </pane-card-item>
                  </pane-card-body>
                </pane-card>
              </pane-item>

              <!-- Configuration Tab -->
              <pane-item id="tab-config" :isActive="false">
                <pane-card title="Viewer Settings" icon="cogs">
                  <pane-card-body>
                    <pane-card-item caption="Require Login to Watch" value="">
                    </pane-card-item>
                    <pane-card-item
                      caption="CloudFormation Pre-start Time"
                      value=""
                    >
                    </pane-card-item>
                  </pane-card-body>
                </pane-card>
              </pane-item>

              <!-- Landing Page Tab -->
              <pane-item id="tab-landing" :isActive="false">
                <pane-card title="Landing Page Configuration" icon="home">
                  <pane-card-body>
                    <pane-card-item caption="Global Featured Items" value="">
                    </pane-card-item>
                    <pane-card-item
                      caption="Number of days shown in 'Live & Upcoming'"
                      value=""
                    >
                    </pane-card-item>
                  </pane-card-body>
                </pane-card>
              </pane-item>

              <!-- Diagnostics Tab -->
              <pane-item id="tab-diagnostics" :isActive="false">
                <pane-card title="Single Sign On" icon="key">
                  <pane-card-body>
                    <pane-card-item
                      caption="Backend Authentication Token (JWT)"
                      value=" "
                    >
                      <pane-card-item-button
                        @click="refreshToken"
                        icon="arrows-rotate"
                        :enabled="true"
                        type="edit"
                      ></pane-card-item-button>
                      <pane-card-item-button
                        @click="copyToken"
                        icon="clipboard-list"
                        :enabled="true"
                        type="edit"
                      ></pane-card-item-button>
                    </pane-card-item>
                  </pane-card-body>
                </pane-card>

                <pane-card title="Database" icon="database">
                  <pane-card-body>
                    <pane-card-item
                      caption="Database Model"
                      :value="system.backend.version.database"
                    >
                    </pane-card-item>
                    <pane-card-item
                      caption="Last execution timestamp"
                      :value="system.backend.db.lastMigrationTimestamp"
                    >
                    </pane-card-item>
                    <pane-card-item
                      caption="Last execution model"
                      :value="system.backend.db.lastMigrationName"
                    >
                    </pane-card-item>
                    <pane-card-item caption="Execute Migrations" value=" ">
                      <pane-card-item-button
                        @click="performDatabaseMigration"
                        icon="circle-play"
                        :enabled="true"
                        type="delete"
                      ></pane-card-item-button>
                    </pane-card-item>
                    <pane-card-item caption="Execute Rollback" value=" ">
                      <pane-card-item-button
                        icon="trash-slash"
                        :enabled="false"
                        type="delete"
                      ></pane-card-item-button>
                    </pane-card-item>
                  </pane-card-body>
                </pane-card>
              </pane-item>
            </pane>
          </pane-card>
        </pane-item>

        <!-- Users Pane -->
        <pane-item id="users" :isActive="false">
          <pane-card title="Users" icon="user">
            <pane-card-body>
              <loading-div
                :visible="!usersTable.dataReady"
                icon="gear"
                message="Loading users..."
              ></loading-div>
              <div v-if="usersTable.dataReady">
                <div class="row g-3">
                  <div class="col-sm-2">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">Field:</span>
                      <select
                        class="form-select"
                        v-model="usersTable.searchField"
                      >
                        <option value="" selected>All fields</option>
                        <option value="uuid">UUID</option>
                        <option value="email">Email</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">Value:</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="usersTable.searchValue"
                      />
                    </div>
                  </div>
                </div>

                <datatable
                  :headers="usersTable.headers"
                  :items="usersTable.items"
                  :search-field="usersTable.searchField"
                  :search-value="usersTable.searchValue"
                />
              </div>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Media Pane -->
        <pane-item id="media" :isActive="false">
          <pane-card title="Media" icon="tv-retro">
            <pane-card-body>
              <loading-div
                :visible="!mediaTable.dataReady"
                icon="compact-disc"
                message="Loading media..."
              ></loading-div>
              <div v-if="mediaTable.dataReady">
                <div class="row g-3">
                  <div class="col-sm-2">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">Field:</span>
                      <select
                        class="form-select"
                        v-model="mediaTable.searchField"
                      >
                        <option value="" selected>All fields</option>
                        <option
                          v-for="(field, index) in mediaTableSearchableFields"
                          :key="index"
                          :value="field.value"
                        >
                          {{ field.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">Value:</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="mediaTable.searchValue"
                      />
                    </div>
                  </div>
                </div>

                <datatable
                  :headers="mediaTable.headers"
                  :items="mediaTable.items"
                  :search-field="mediaTable.searchField"
                  :search-value="mediaTable.searchValue"
                  sort-by="startDateUTC"
                  sort-type="desc"
                >
                  <template #item-mediaID="item">
                    <a :href="'/view/' + item.mediaID">{{ item.mediaID }}</a>
                  </template>
                  <template #item-startDateUTC="item">
                    {{ formatDate(item.startDateUTC) }}
                  </template>
                  <template #item-endDateUTC="item">
                    {{ formatDate(item.endDateUTC) }}
                  </template>
                  <template #item-scoringEnabled="item">
                    <fa :icon="['fad', boolToIcon(item.scoringEnabled)]"></fa>
                  </template>
                  <template #item-isPublished="item">
                    <fa :icon="['fad', boolToIcon(item.isPublished)]"></fa>
                  </template>
                  <template #item-isLive="item">
                    <fa :icon="['fad', boolToIcon(item.isLive)]"></fa>
                  </template>
                  <template #item-isVod="item">
                    <fa :icon="['fad', boolToIcon(item.isVod)]"></fa>
                  </template>
                  <template #item-manage="item">
                    <a :href="'/manage/media/' + item.mediaID">
                      <fa :icon="['fad', 'bars-progress']"></fa>
                    </a>
                  </template>
                  <template #item-edit="item">
                    <a href="" @click="editMedia(item.mediaID)">
                      <fa :icon="['fad', 'cog']"></fa>
                    </a>
                  </template>
                </datatable>
              </div>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Hardware Pane -->
        <pane-item id="hardware" :isActive="false">
          <pane-card title=" Elemental Link Devices" icon="satellite">
            <pane-card-body>
              <loading-div
                :visible="!awsLinkHardwareTable.dataReady"
                icon="gear"
                message="Loading Hardware Information..."
              ></loading-div>
              <div v-if="awsLinkHardwareTable.dataReady">
                <datatable
                  :headers="awsLinkHardwareTable.headers"
                  :items="awsLinkHardwareTable.items"
                >
                  <template #item-DeviceUpdateStatus="item">
                    {{ formatDeviceSoftwareStatus(item.DeviceUpdateStatus) }}
                  </template>
                  <template #item-DeviceState="item">
                    {{ formatDeviceState(item.video.DeviceState) }}
                  </template>
                  <template #item-ActiveInput="item">
                    {{ formatDeviceState(item.video.ActiveInput) }}
                  </template>
                </datatable>
              </div>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Workers Pane -->
        <pane-item id="workers" :isActive="false">
          <pane-card title="Automation Jobs" icon="robot">
            <pane-card-body>
              <div v-if="workerStatus.dataReady">
                <pane-card-item
                  caption="Worker State"
                  :value="' ' + formatWorkerState(system.worker.state)"
                >
                  <pane-card-item-button
                    v-if="!system.worker.state"
                    @click="workerStart"
                    icon="play"
                    :enabled="true"
                    type="add"
                  ></pane-card-item-button>
                  <pane-card-item-button
                    v-if="system.worker.state"
                    @click="workerStop"
                    icon="hand"
                    :enabled="true"
                    type="delete"
                  ></pane-card-item-button>
                </pane-card-item>
                <pane-card-item
                  caption="Last Execution (UTC)"
                  :value="' ' + system.worker.lastExecuted"
                >
                  <pane-card-item-button
                    icon="pen-slash"
                    :enabled="false"
                    type="edit"
                  ></pane-card-item-button>
                </pane-card-item>
              </div>
            </pane-card-body>
            <div class="row px-4 pb-3" v-if="workerStatus.dataReady">
              <div
                class="description-box d-flex p-0 justify-content-between align-items-center"
              >
                <p class="p-2 flex-grow-1" v-html="system.worker.lastLog"></p>
              </div>
            </div>
          </pane-card>
        </pane-item>

        <!-- Cloud Pane -->
        <pane-item id="cloud" :isActive="false">
          <pane-card title="CloudFormation Templates" icon="clouds">
            <pane-card-body>
              <div class="row mx-2 mt-2">
                <div
                  class="list-group-item d-flex justify-content-between align-items-center"
                  style="height: unset !important"
                >
                  <div class="col-1">
                    <span>Name:</span>
                  </div>
                  <div class="col-10">
                    <select class="form-select">
                      <option>ABC</option>
                      <option>ABC</option>
                      <option>ABC</option>
                    </select>
                  </div>
                  <div
                    class="col-1 py-3 d-grid gap-2 d-md-flex justify-content-md-end"
                  >
                    <fa :icon="['fad', 'save']" class="btn btn-secondary"></fa>
                    <fa :icon="['fad', 'plus']" class="btn btn-success"></fa>
                    <!--aws_cf_btnAddNew -->
                  </div>
                </div>
              </div>

              <div class="row mx-2 mb-2">
                <div
                  class="list-group-item d-flex p-0"
                  style="min-height: 600px"
                >
                  <div class="col-9">
                    <v-ace-editor
                      v-model:value="cftemplate"
                      @init="editorInit"
                      lang="json"
                      :options="{ useWorker: true }"
                      theme="monokai"
                      style="min-height: 600px"
                    />
                  </div>
                  <div class="col-3 p-2">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                      <h5>Parameters</h5>
                      <!-- aws_cf_btnAddParameter -->
                      <fa
                        :icon="['fad', 'plus']"
                        class="btn btn-secondary ml-1"
                      ></fa>
                    </div>
                    <div id="aws_templateParameters"></div>
                  </div>
                </div>
              </div>
            </pane-card-body>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import LoadingDiv from "@/components/LoadingDiv";

import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import TabsTopbar from "@/components/ConsolePane/TabsTopbar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";

import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";

import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";

import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Console",
  components: {
    LoadingDiv,
    PaneSidebar,
    TabsTopbar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
  },
  computed: {
    ...mapState({
      rootData: (state) => state.organisations,
    }),
    system() {
      return this.$store.getters.getSystemConfiguration;
    },
    frontendEnvironment() {
      return process.env.VUE_APP_ENVIROMENT;
    },
    mediaTableSearchableFields() {
      return this.mediaTable.headers.filter((header) => header.searchable);
    },
  },
  data() {
    return {
      dataReady: false,
      token: "",
      usersTable: {
        dataReady: false,
        searchField: "",
        searchValue: "",
        headers: [
          { text: "UUID", value: "uuid" },
          { text: "First Name", value: "firstName" },
          { text: "Last Name", value: "lastName" },
          { text: "Email", value: "email" },
        ],
        items: [],
      },
      mediaTable: {
        dataReady: false,
        searchField: "",
        searchValue: "",
        headers: [
          {
            text: "Media ID",
            value: "mediaID",
            searchable: true,
            sortable: true,
          },
          {
            text: "Media Name",
            value: "mediaName",
            searchable: true,
            sortable: true,
          },
          {
            text: "Venue",
            value: "mediaShortDescription",
            searchable: true,
            sortable: true,
          },
          {
            text: "Organisation",
            value: "organisation",
            searchable: true,
            sortable: true,
          },
          {
            text: "Channel",
            value: "channel",
            searchable: true,
            sortable: true,
          },
          {
            text: "Catalog",
            value: "catalog",
            searchable: true,
            sortable: true,
          },
          {
            text: "Scheduled Start (Local)",
            value: "startDateUTC",
            searchable: false,
            sortable: true,
          },
          {
            text: "Scheduled End (Local)",
            value: "endDateUTC",
            searchable: false,
            sortable: true,
          },
          { text: "Scoring", value: "scoringEnabled", searchable: false },
          { text: "Published", value: "isPublished", searchable: false },
          { text: "Live", value: "isLive", searchable: false },
          { text: "VOD", value: "isVod", searchable: false },
          { text: " ", value: "manage", searchable: false },
          { text: " ", value: "edit", searchable: false },
        ],
        items: [],
      },
      awsLinkHardwareTable: {
        dataReady: false,
        headers: [
          { text: "Device Name", value: "deviceName" },
          { text: "Device ID", value: "deviceUUID" },
          { text: "IP Address", value: "IP" },
          { text: "Connection State", value: "connectionState" },
          { text: "Software", value: "DeviceUpdateStatus" },
          { text: "Status", value: "DeviceState" },
          { text: "Status", value: "ActiveInput" },
        ],
        items: [],
      },
      workerStatus: {},
      cftemplate: "",
    };
  },
  methods: {
    refreshToken() {
      this.token = "";
      this.token = localStorage.getItem("sso_token");
    },
    copyToken() {
      this.refreshToken();
      navigator.clipboard.writeText(this.token);
      this.$notify({
        title: "SSO Token",
        text: "The SSO token has been copied to the clipboard.",
        type: "success",
      });
    },
    performDatabaseMigration() {
      this.$swal({
        title: "Migrate Database",
        text: "Are you sure you want to continue?",
        showCancelButton: true,
        confirmButtonText: "Migrate",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("performDatabaseMigration").then((res) => {
            if (res.status) {
              this.$swal({
                title: "Migration Success",
                text: res.result,
                icon: "success",
              });
            } else {
              this.$swal({
                showCancelButton: true,
                showConfirmButton: false,
                title: "Error!",
                text: res.result,
                icon: "error",
              });
            }
          });
        }
      });
    },
    async initUsersPane() {
      this.usersTable.dataReady = false;
      await this.$store.dispatch("getUserPoolDataset").then(() => {
        console.log("Users loaded");
        this.usersTable.items = this.$store.getters.getUserPool;
        this.usersTable.dataReady = true;
      });
    },
    async initMediaPane() {
      this.mediaTable.dataReady = false;
      this.$store.dispatch("initData").then(() => {
        this.mediaTable.items = [];
        this.rootData.forEach((org) => {
          org.channels.forEach((chan) => {
            chan.catalogs.forEach((cat) => {
              cat.media.forEach((media) => {
                media.organisation = org.organisationName;
                media.channel = chan.channelName;
                media.catalog = cat.catalogName;
                this.mediaTable.items.push(media);
              });
            });
          });
        });
        this.mediaTable.dataReady = true;
      });
    },
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
    boolToIcon(v) {
      if (v) {
        return "check";
      }
      return "xmark";
    },
    editMedia(id) {
      this.$router.push("/console/media/" + id);
    },
    async initHardwarePane() {
      this.awsLinkHardwareTable.dataReady = false;
      await this.$store.dispatch("getAwsLinkHardwareDataset").then(() => {
        console.log("Hardware loaded");
        this.awsLinkHardwareTable.items =
          this.$store.getters.getAwsLinkHardwarePool;
        console.log(this.awsLinkHardwareTable.items);
        this.awsLinkHardwareTable.dataReady = true;
      });
    },
    formatDeviceSoftwareStatus(i) {
      switch (i) {
        case "UP_TO_DATE":
          return "Up to date";
        case "NOT_UP_TO_DATE":
          return "Not up to date";
        default:
          return "-";
      }
    },
    formatDeviceState(i) {
      if (typeof i === "undefined") {
        return "-";
      } else {
        return i;
      }
    },
    async initWorkerPane() {
      this.workerStatus.dataReady = false;
      await this.$store.dispatch("getWorkerDataset").then(() => {
        console.log("Worker loaded");
        this.workerStatus.data = this.$store.getters.getWorkerDataset;
        this.workerStatus.dataReady = true;
      });
    },
    formatWorkerState(w) {
      if (w) {
        return "Running";
      }
      return "Stopped";
    },
    workerStart() {
      this.$swal({
        title: "Start Worker",
        text: "Are you sure you want to continue?",
        showCancelButton: true,
        confirmButtonText: "Start",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("startWorker").then((res) => {
            if (res.status) {
              this.$swal({
                title: "Worker Started",
                icon: "success",
              });
              this.system.worker.state = 1;
            } else {
              this.$swal({
                showCancelButton: true,
                showConfirmButton: false,
                title: "Error!",
                text: res.result,
                icon: "error",
              });
            }
          });
        }
      });
    },
    workerStop() {
      this.$swal({
        title: "Stop Worker",
        text: "Are you sure you want to continue?",
        showCancelButton: true,
        confirmButtonText: "Stop",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("stopWorker").then((res) => {
            if (res.status) {
              this.$swal({
                title: "Worker Stopped",
                icon: "success",
              });
              this.system.worker.state = 0;
            } else {
              this.$swal({
                showCancelButton: true,
                showConfirmButton: false,
                title: "Error!",
                text: res.result,
                icon: "error",
              });
            }
          });
        }
      });
    },
    editorInit: function () {},
  },
  async mounted() {
    this.refreshToken();
    await this.$store.dispatch("getBackendConfiguration").then(() => {
      this.dataReady = true;
    });
    this.$store.commit("setPageTitle", "Administration");
  },
  unmounted() {},
};
</script>

<style scoped></style>
