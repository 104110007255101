import { createStore } from "vuex";
import axios from "axios";
import _ from "lodash";

// API constants
const apiUrl = process.env.VUE_APP_BACKEND_URL;
let token = localStorage.getItem("sso_token");

let headers = {};
let headersForm = {};

if (token) {
  headers = { Authorization: "Bearer " + token };
  headersForm = {
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data",
  };
}

export default createStore({
  state: {
    title: "",
    system: {
      backend: {
        version: {
          application: "",
          database: "",
          environment: "",
        },
      },
      users: {},
      aws: {
        linkHardware: {},
      },
      worker: {},
    },
    user: {},
    featuredItemsCarousel: [],
    liveAndUpcomingMedia: [],
    catalogs: [],
    currentMedia: [],
    organisations: [],
  },
  getters: {
    getSystemConfiguration: (state) => {
      return state.system ?? {};
    },
    getUserPool: (state) => {
      return state.system.users ?? {};
    },
    getAwsLinkHardwarePool: (state) => {
      return state.system.aws.linkHardware;
    },
    getWorkerStatus: (state) => {
      return state.system.worker ?? {};
    },

    getUser: (state) => {
      return state.user;
    },

    getCurrentMedia: (state) => {
      return state.currentMedia;
    },

    findCatalog: (state) => (catalogID) =>
      state.catalogs.find((c) => c.catalogID === catalogID),

    findOrganisationByVanity: (state) => (organisationVanity) =>
      state.organisations.find((o) => o.vanityUrl === organisationVanity),

    findOrganisationByID: (state) => (organisationID) =>
      state.organisations.find((o) => o.organisationID === organisationID),

    findChannelByID: (state) => (channelID) => {
      for (let organisation of state.organisations) {
        for (let channel of organisation.channels) {
          if (channel.channelID === channelID) {
            return channel;
          }
        }
      }
    },

    findCatalogByID: (state) => (catalogID) => {
      for (let organisation of state.organisations) {
        for (let channel of organisation.channels) {
          for (let catalog of channel.catalogs) {
            if (catalog.catalogID === catalogID) {
              return catalog;
            }
          }
        }
      }
    },

    findMediaByID: (state) => (mediaID) => {
      for (let organisation of state.organisations) {
        for (let channel of organisation.channels) {
          for (let catalog of channel.catalogs) {
            for (let media of catalog.media) {
              if (media.mediaID === mediaID) {
                return media;
              }
            }
          }
        }
      }
    },
  },
  mutations: {
    setPageTitle(state, title) {
      state.title = title;
    },

    setSystemConfiguration(state, configuration) {
      state.system.backend = configuration;
    },
    setUserPoolDataset(state, dataset) {
      state.system.users = dataset;
    },
    setAwsLinkHardwareDataset(state, dataset) {
      if (!state.system.aws) {
        state.system.aws = {};
      }
      state.system.aws.linkHardware = dataset;
    },
    setWorkerDataset(state, dataset) {
      state.system.worker = dataset;
    },

    setUser(state, user) {
      Object.assign(state.user, state.user, user);
    },
    unsetUser(state) {
      delete state.user;
    },

    setOrganisations(state, organisations) {
      state.organisations = organisations;
    },
    setCatalogs(state, catalogs) {
      state.catalogs = catalogs;
    },
    setMedia(state, media) {
      Object.assign(state.media, state.user, media);
    },

    setFeatureItemsCarousel(state, media) {
      state.featuredItemsCarousel = media;
    },
    setLiveAndUpcomingMedia(state, media) {
      state.liveAndUpcomingMedia = media;
    },
  },
  actions: {
    async setCurrentMediaDataset(context, mediaID) {
      await context.dispatch("configureCatalogs").then(async function () {
        _.forEach(context.state.catalogs, (catalog) => {
          _.forOwn(catalog, function (value, key) {
            if (key === "media") {
              _.forEach(value, (media) => {
                if (media.mediaID === mediaID) {
                  context.state.currentMedia = media;
                }
              });
            }
          });
        });
      });
    },

    // Entry point to configure data store
    async initData(context) {
      await context
        .dispatch("getUser")
        .then(async function () {
          await context.dispatch("getLiveAndUpcomingMedia");
        })
        .then(async function () {
          await context.dispatch("getFeatureItemsCarousel");
        })
        .then(async function () {
          await context.dispatch("configureOrganisations");
        })
        .then(async function () {
          await context.dispatch("configureCatalogs");
        });
    },

    // Populate store ORGANISATION data
    async configureOrganisations(context) {
      await context
        .dispatch("getOrganisations")
        .then(async function () {
          await context.dispatch("getChannels");
        })
        .then(async function () {
          await context.dispatch("getCatalogs");
        })
        .then(async function () {
          await context.dispatch("getMedia");
        })
        .then(async function () {
          await context.dispatch("getOrgFeaturedMedia");
        })
        .then(async function () {
          await context.dispatch("getOrgLiveAndUpcoming");
        })
        .then(async function () {
          await context.dispatch("getAllCatalogOrgName");
        });
    },
    // Populate store CATALOG data (all catalogs, unfiltered) (to be deprecated!)
    async configureCatalogs(context) {
      await context
        .dispatch("getAllCatalogs")
        .then(async function () {
          await context.dispatch("getAllCatalogMedia");
        })
        .then(async function () {
          await context.dispatch("getAllCatalogOrgName");
        });
    },

    // == ADMIN CONSOLE ACTIONS ==
    async getBackendConfiguration(context) {
      await axios
        .get(apiUrl + "/api/system/configuration", { headers: headers })
        .then((res) => {
          if (res.data.status) {
            context.commit("setSystemConfiguration", res.data.result);
          }
        });
    },

    async performDatabaseMigration() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            apiUrl + "/api/db/migrate",
            {},
            {
              headers: headers,
            }
          )
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    async getUserPoolDataset(context) {
      await axios
        .get(apiUrl + "/api/console/users", { headers: headers })
        .then((res) => {
          if (res.data.status) {
            context.commit("setUserPoolDataset", res.data.result);
          }
        });
    },

    async getAwsLinkHardwareDataset(context) {
      await axios
        .get(apiUrl + "/api/console/aws/link/detailed", { headers: headers })
        .then((res) => {
          if (res.data.status) {
            context.commit("setAwsLinkHardwareDataset", res.data.devices);
          }
        });
    },

    async getWorkerDataset(context) {
      await axios
        .get(apiUrl + "/api/worker/status", { headers: headers })
        .then((res) => {
          if (res.data.status) {
            context.commit("setWorkerDataset", res.data.result);
          }
        });
    },

    async startWorker() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            apiUrl + "/api/worker/start",
            {},
            {
              headers: headers,
            }
          )
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    async stopWorker() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            apiUrl + "/api/worker/stop",
            {},
            {
              headers: headers,
            }
          )
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    // == USER ACTIONS ==
    getUser(context) {
      let sso = window.$sso;
      let ssoClientID = process.env.VUE_APP_SSO_CLIENT;
      if (sso.authenticated) {
        sso.loadUserProfile().then(async function (r) {
          token = localStorage.getItem("sso_token");
          headers = { Authorization: "Bearer " + token };
          await axios
            .get(apiUrl + "/api/user", { headers: headers })
            .then(async function () {
              let admin = false;
              if (window.$sso.resourceAccess[ssoClientID]) {
                admin =
                  window.$sso.resourceAccess[ssoClientID].roles.includes(
                    "admin"
                  ) ?? false;
              }
              let user = {
                sub: sso.subject,
                firstName: r.firstName,
                lastName: r.lastName,
                email: r.email,
                isAdmin: admin,
              };
              context.commit("setUser", user);
            });
        });
      }
    },

    async getUsersObjects(context, params) {
      let type = params.type;
      await axios
        .get(apiUrl + "/api/user/" + type, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            let userData = context.getters.getUser;
            let payload = { [type]: res.data.result };
            Object.assign(userData, userData, payload);
          }
        });
    },

    // == MEDIA ACTIONS ==
    async getOrganisations(context) {
      await axios
        .get(apiUrl + "/api/organisation", { headers: headers })
        .then((res) => {
          if (res.data.status) {
            context.commit("setOrganisations", res.data.result);
          }
        });
    },
    async getChannels(context) {
      for (let organisation of context.state.organisations) {
        let organisationID = organisation.organisationID;
        await axios
          .get(apiUrl + "/api/organisation/" + organisationID + "/channels", {
            headers: headers,
          })
          .then((res) => {
            if (res.data.status) {
              let channelData = { channels: res.data.result };
              delete organisation.channels;
              Object.assign(organisation, organisation, channelData);
            }
          });
      }
    },
    async getCatalogs(context) {
      for (let organisation of context.state.organisations) {
        for (let channel of organisation.channels) {
          let channelID = channel.channelID;
          await axios
            .get(apiUrl + "/api/channel/" + channelID + "/catalogs", {
              headers: headers,
            })
            .then((res) => {
              if (res.data.status) {
                let catalogData = { catalogs: res.data.result };
                delete channel.catalogs;
                Object.assign(channel, channel, catalogData);
              }
            });
        }
      }
    },
    async getMedia(context) {
      for (let organisation of context.state.organisations) {
        for (let channel of organisation.channels) {
          for (let catalog of channel.catalogs) {
            let catalogID = catalog.catalogID;
            await axios
              .get(apiUrl + "/api/catalog/" + catalogID + "/media", {
                headers: headers,
              })
              .then((res) => {
                if (res.data.status) {
                  let mediaData = { media: res.data.result };
                  delete catalog.media;
                  Object.assign(catalog, catalog, mediaData);
                }
              });
          }
        }
      }
    },
    async getOrgFeaturedMedia(context) {
      for (let organisation of context.state.organisations) {
        let organisationID = organisation.organisationID;
        await axios
          .get(apiUrl + "/api/organisation/" + organisationID + "/featured", {
            headers: headers,
          })
          .then((res) => {
            if (res.data.status) {
              let featuredMedia = { featured: res.data.result };
              Object.assign(organisation, organisation, featuredMedia);
            }
          });
      }
    },
    async getOrgLiveAndUpcoming(context) {
      for (let organisation of context.state.organisations) {
        let organisationID = organisation.organisationID;
        await axios
          .get(
            apiUrl + "/api/organisation/" + organisationID + "/liveandupcoming",
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.data.status) {
              let liveRow = { liverow: res.data.result };
              Object.assign(organisation, organisation, liveRow);
            }
          });
      }
    },

    async getOrgAdminUsers(context, params) {
      let organisationID = params.organisationID;
      await axios
        .get(apiUrl + "/api/organisation/" + organisationID + "/users", {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            let organisationData =
              context.getters.findOrganisationByID(organisationID);
            let adminUsers = { admins: res.data.result };
            delete organisationData.admins;
            Object.assign(organisationData, organisationData, adminUsers);
          }
        });
    },

    async getChannelAdminUsers(context, params) {
      let channelID = params.channelID;
      await axios
        .get(apiUrl + "/api/channel/" + channelID + "/users", {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            let channelData = context.getters.findChannelByID(channelID);
            let adminUsers = { admins: res.data.result };
            delete channelData.admins;
            Object.assign(channelData, channelData, adminUsers);
          }
        });
    },

    async getCatalogAdminUsers(context, params) {
      let catalogID = params.catalogID;
      await axios
        .get(apiUrl + "/api/catalog/" + catalogID + "/users", {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            let catalogData = context.getters.findCatalogByID(catalogID);
            let adminUsers = { admins: res.data.result };
            delete catalogData.admins;
            Object.assign(catalogData, catalogData, adminUsers);
          }
        });
    },

    async getMediaAdminUsers(context, params) {
      let mediaID = params.mediaID;
      await axios
        .get(apiUrl + "/api/media/" + mediaID + "/users", {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            let mediaData = context.getters.findMediaByID(mediaID);
            let adminUsers = { admins: res.data.result };
            delete mediaData.admins;
            Object.assign(mediaData, mediaData, adminUsers);
          }
        });
    },

    async setOrganisationField(context, params) {
      return new Promise((resolve, reject) => {
        let organisationID = params.organisationID;
        let data = params.data;

        axios
          .post(apiUrl + "/api/organisation/" + organisationID, data, {
            headers: headersForm,
          })
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    async setChannelField(context, params) {
      return new Promise((resolve, reject) => {
        let channelID = params.channelID;
        let data = params.data;

        axios
          .post(apiUrl + "/api/channel/" + channelID, data, {
            headers: headersForm,
          })
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    async setCatalogField(context, params) {
      return new Promise((resolve, reject) => {
        let catalogID = params.catalogID;
        let data = params.data;

        axios
          .post(apiUrl + "/api/catalog/" + catalogID, data, {
            headers: headersForm,
          })
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    async setMediaField(context, params) {
      return new Promise((resolve, reject) => {
        let mediaID = params.mediaID;
        let data = params.data;

        axios
          .post(apiUrl + "/api/media/" + mediaID, data, {
            headers: headersForm,
          })
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    async setMediaStreamState(context, params) {
      return new Promise((resolve, reject) => {
        let mediaID = params.mediaID;
        let streamAction = params.streamAction;

        axios
          .post(
            apiUrl + "/api/media/" + mediaID + "/" + streamAction,
            {},
            {
              headers: headers,
            }
          )
          .then(
            (res) => {
              if (res.data.status) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    // ================================== //

    // == CATALOG ACTIONS (UNFILTERED) ==
    async getAllCatalogs(context) {
      await axios
        .get(apiUrl + "/api/catalog", { headers: headers })
        .then((res) => {
          if (res.data.status) {
            context.commit("setCatalogs", res.data.result);
          }
        });
    },
    async getAllCatalogMedia(context) {
      for (let catalog of context.state.catalogs) {
        let catalogID = catalog.catalogID;
        await axios
          .get(apiUrl + "/api/catalog/" + catalogID + "/media", {
            headers: headers,
          })
          .then((res) => {
            if (res.data.status) {
              let catalogData = context.getters.findCatalog(catalogID);
              let media = { media: res.data.result };
              delete catalogData.media;
              Object.assign(catalogData, catalogData, media);
            }
          });
      }
    },
    async getAllCatalogOrgName(context) {
      for (let catalog of context.state.catalogs) {
        let catalogID = catalog.catalogID;
        for (let organisation of context.state.organisations) {
          for (let channel of organisation.channels) {
            for (let cat of channel.catalogs) {
              if (cat.catalogID === catalogID) {
                let subtitle = { subtitle: organisation.organisationName };
                Object.assign(catalog, catalog, subtitle);
              }
            }
          }
        }
      }
    },

    // == SPECIAL ACTIONS ==
    async getFeatureItemsCarousel(context) {
      await axios
        .get(apiUrl + "/api/home/featured", {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            context.commit("setFeatureItemsCarousel", res.data.result);
          }
        });
    },
    async getLiveAndUpcomingMedia(context) {
      await axios
        .get(apiUrl + "/api/catalog/live/media", {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status) {
            context.commit("setLiveAndUpcomingMedia", res.data.result);
          }
        });
    },
  },
  modules: {},
});
