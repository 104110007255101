<template>
  <div id="manage" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="general"
          caption="My Profile"
          icon="user"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          v-if="countOrganisations"
          id="organisations"
          caption="My Organisations"
          icon="sitemap"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          v-if="countChannels"
          id="channels"
          caption="My Channels"
          icon="tv-retro"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          v-if="countCatalogs"
          id="catalogs"
          caption="My Catalogs"
          icon="book-open"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          v-if="countMedia"
          id="stream"
          caption="My Streams"
          icon="photo-film-music"
          :isActive="false"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- User Profile Pane -->
        <pane-item id="general" :isActive="true">
          <pane-card title="User Details" icon="square-info">
            <pane-card-body>
              <pane-card-item
                caption="Name"
                :value="user.firstName + ' ' + user.lastName"
              >
              </pane-card-item>
              <pane-card-item caption="User ID" :value="user.sub">
              </pane-card-item>
              <pane-card-item caption="Email" :value="user.email">
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- My Organisations Pane -->
        <pane-item id="organisations" :isActive="false">
          <pane-card title="My Organisations" icon="sitemap">
            <pane-card-body>
              <pane-card-item
                v-for="(organisation, index) in user.organisations"
                :key="index"
                :caption="organisation.organisationName"
                :value="'#' + organisation.organisationID"
              >
                <pane-card-item-button
                  @click="
                    clickLink('organisation', organisation.organisationID)
                  "
                  icon="chevrons-right"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- My Channels Pane -->
        <pane-item id="channels" :isActive="false">
          <pane-card title="My Channels" icon="tv-retro">
            <pane-card-body>
              <pane-card-item
                v-for="(channel, index) in user.channels"
                :key="index"
                :caption="channel.channelName"
                :value="'#' + channel.channelID"
              >
                <pane-card-item-button
                  @click="clickLink('channel', channel.channelID)"
                  icon="chevrons-right"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- My Catalogs Pane -->
        <pane-item id="catalogs" :isActive="false">
          <pane-card title="My Catalogs" icon="book-open">
            <pane-card-body>
              <pane-card-item
                v-for="(catalog, index) in user.catalogs"
                :key="index"
                :caption="catalog.catalogName"
                :value="'#' + catalog.catalogID"
              >
                <pane-card-item-button
                  @click="clickLink('catalog', catalog.catalogID)"
                  icon="chevrons-right"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- My Streams Pane -->
        <pane-item id="stream" :isActive="false">
          <pane-card title="My Streams" icon="photo-film-music">
            <pane-card-body>
              <loading-div
                :visible="!streamsTable.dataReady"
                icon="gear"
                message="Loading Streams..."
              ></loading-div>
              <div v-if="streamsTable.dataReady">
                <div class="row g-3">
                  <div class="col-sm-2">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">Field:</span>
                      <select
                        class="form-select"
                        v-model="streamsTable.searchField"
                      >
                        <option value="" selected>All fields</option>
                        <option
                          v-for="(field, index) in streamsTableSearchableFields"
                          :key="index"
                          :value="field.value"
                        >
                          {{ field.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">Value:</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="streamsTable.searchValue"
                      />
                    </div>
                  </div>
                </div>

                <datatable
                  :headers="streamsTable.headers"
                  :items="streamsTable.items"
                  :search-field="streamsTable.searchField"
                  :search-value="streamsTable.searchValue"
                  sort-by="startDateUTC"
                  sort-type="desc"
                >
                  <template #item-startDateUTC="item">
                    {{ formatDate(item.startDateUTC) }}
                  </template>
                  <template #item-view="item">
                    <fa
                      :icon="['fad', 'tv']"
                      @click="clickWatchLink(item.mediaID)"
                    ></fa>
                  </template>
                  <template #item-manage="item">
                    <fa
                      :icon="['fad', 'cog']"
                      @click="clickLink('media', item.mediaID)"
                    ></fa>
                  </template>
                </datatable>
              </div>
            </pane-card-body>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";
import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";
import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";
import moment from "moment";

export default {
  name: "Profile",
  components: {
    PaneSidebar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
  },
  props: {},
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    dataReady() {
      return typeof this.user !== "undefined";
    },
    countOrganisations() {
      if (typeof this.user.organisations !== "undefined") {
        return this.user.organisations.length;
      }
      return 0;
    },
    countChannels() {
      if (typeof this.user.channels !== "undefined") {
        return this.user.channels.length;
      }
      return 0;
    },
    countCatalogs() {
      if (typeof this.user.catalogs !== "undefined") {
        return this.user.catalogs.length;
      }
      return 0;
    },
    countMedia() {
      if (typeof this.user.media !== "undefined") {
        return this.user.media.length;
      }
      return 0;
    },
    streamsTableSearchableFields() {
      return this.streamsTable.headers.filter((header) => header.searchable);
    },
  },
  data() {
    return {
      streamsTable: {
        dataReady: false,
        searchField: "",
        searchValue: "",
        headers: [
          { text: "ID", value: "mediaID", searchable: true, sortable: true },
          {
            text: "Name",
            value: "mediaName",
            searchable: true,
            sortable: true,
          },
          {
            text: "Stream Start",
            value: "startDateUTC",
            searchable: true,
            sortable: true,
          },
          { text: "View", value: "view", searchable: false },
          { text: "Manage", value: "manage", searchable: false },
        ],
        items: [],
      },
    };
  },
  mounted() {
    this.$store.commit("setPageTitle", "My Profile");
    this.$store.dispatch("getUsersObjects", {
      type: "organisations",
    });
    this.$store.dispatch("getUsersObjects", {
      type: "channels",
    });
    this.$store.dispatch("getUsersObjects", {
      type: "catalogs",
    });
    this.$store
      .dispatch("getUsersObjects", {
        type: "media",
      })
      .then(() => {
        this.streamsTable.items = this.user.media;
        this.streamsTable.dataReady = true;
      });
  },
  methods: {
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
    clickLink: function (route, id) {
      this.$router.push("/manage/" + route + "/" + id);
    },
    clickWatchLink: function (id) {
      this.$router.push("/view/" + id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
