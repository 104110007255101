<template>
  <!-- DOG bug -->
  <div  v-if="bugPos === 'left'" class="dog-bug-left dog-animate-left">
    <img :src="bugImg"  alt="Anywhere Live"/>
  </div>
  <div  v-else class="dog-bug dog-animate">
    <img :src="bugImg"  alt="Anywhere Live"/>
  </div>

  <!-- score bug -->
  <div class="score-bug">
    <div class="rugby" :class="{ enabled: score.scoreVisible }">
      <div class="name left" :style="{ background: score.teamAColour }">
        {{ score.teamAName }}
      </div>
      <div class="name right" :style="{ background: score.teamBColour }">
        {{ score.teamBName }}
      </div>
      <div
        class="score left"
        :style="{ background: shadeColor(score.teamAColour, -20) }"
      >
        {{ score.teamAScore }}
      </div>
      <div
        class="score right"
        :style="{ background: shadeColor(score.teamBColour, -20) }"
      >
        {{ score.teamBScore }}
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";

export default {
  name: "OverlayGeneric",
  components: {},
  props: ["mediaID", "bugImg", "bugPos"],
  computed: {
    messageBrokerURL() {
      return process.env.VUE_APP_BROKER_URL;
    },
  },
  data() {
    return {
      socket: null,
      score: {
        scoreVisible: false,
        teamAName: "Team A",
        teamBName: "Team B",
        teamAColour: "#B26CF0",
        teamBColour: "#FFFFFF",
        teamAScore: 0,
        teamBScore: 0,
      },
    };
  },
  methods: {
    shadeColor: function (color, percent) {
      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      let RR =
        R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
      let GG =
        G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
      let BB =
        B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },
  },
  watch: {},
  mounted() {
    this.score.dataReady = false;

    this.socket = io.connect(this.messageBrokerURL);

    this.socket.on("connect", () => {
      // on connect, send channel to "overlay-subscribe" with role to be linked to appropriate controller
      console.log(
        "Connected to broker. Attempting to join session as display..."
      );
      this.socket.emit("overlay-subscribe", {
        channel: this.mediaID,
        role: "display",
      });

      // handle incoming overlay messages
      this.socket.on(this.mediaID + "-overlay", (payload) => {
        if (payload.type === "score") {
          this.score.scoreVisible = payload.data.scoreVisible;
          this.score.teamAName = payload.data.teamAName;
          this.score.teamBName = payload.data.teamBName;
          this.score.teamAColour = payload.data.teamAColour;
          this.score.teamBColour = payload.data.teamBColour;
          this.score.teamAScore = payload.data.teamAScore;
          this.score.teamBScore = payload.data.teamBScore;
        } else {
          console.log("Message RX: " + payload.msg);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/awl-dimensions.scss";

// BOOTSTRAP
@import "../../styles/overrides/_bootstrap.scss";
@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import "./node_modules/bootstrap/scss/_functions.scss";
@import "./node_modules/bootstrap/scss/_variables.scss";

@import "../../styles/overlay.scss";
</style>
