<template>
  <modal
    v-model="show"
    :name="panelID"
    classes="modal-container"
    content-class="modal-content"
  >
    <!-- Dialog title -->
    <span v-if="title" class="modal__title mb-3">
      <fa v-if="icon" class="pe-1" :icon="['fad', icon]"></fa>
      {{ title }}
    </span>

    <!-- Dialog body -->
    <form ref="frm" @submit.prevent="this.$refs.frm.reset()">
      <div v-for="(input, index) in modal" :key="index" class="mb-3">
        <!-- Text Type -->
        <div v-if="input.type === 'Text'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <input class="form-control" :id="index" v-model="input.value" />
        </div>

        <!-- Textarea Type -->
        <div v-if="input.type === 'Textarea'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <textarea
            class="form-control"
            :id="index"
            v-model="input.value"
            rows="8"
          />
        </div>

        <!-- Number Type -->
        <div v-if="input.type === 'Number'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <input
            type="number"
            class="form-control"
            :id="index"
            v-model="input.value"
            :min="input.min"
            :max="input.max"
          />
        </div>

        <!-- Check Type -->
        <div v-if="input.type === 'Check'" class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            :id="index"
            v-model="input.value"
          />
          <label v-if="input.caption" :for="index" class="form-check-label">
            {{ input.caption }}
          </label>
        </div>

        <!-- Date Type -->
        <div v-if="input.type === 'Date'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <input
            type="datetime-local"
            class="form-control"
            :id="index"
            v-model="input.value"
          />
        </div>

        <!-- Colour Type -->
        <div v-if="input.type === 'Colour'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <input
            type="color"
            class="form-control form-control-color"
            :id="index"
            v-model="input.value"
          />
        </div>

        <!-- File Type -->
        <div v-if="input.type === 'File'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <input
            type="file"
            class="form-control"
            :id="index"
            accept="image/*"
            @change="selectImage($event, index, input.s3key, input.key)"
          />
          <div v-if="preview[index]">
            <div>
              <img
                class="preview my-3"
                :src="preview[index]"
                alt=""
                style="
                  width: 100%;
                  max-height: 200px;
                  object-fit: contain;
                  border: 1px solid #ced4da;
                  background: #e9ecef;
                  border-radius: 5px;
                  padding: 5px;
                "
              />
            </div>
          </div>
        </div>

        <!-- Select Type -->
        <div v-if="input.type === 'Select'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <div class="input-group mb-3">
            <select
              class="form-control form-select"
              :name="index"
              :id="index"
              v-model="input.value"
            >
              <option
                v-for="(option, index) in input.options"
                :key="index"
                :value="option.value"
                :selected="option.selected"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <!-- AWS Params Type -->
        <div v-if="input.type === 'AwsParams'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <textarea
            class="form-control"
            :id="index"
            v-model="input.value"
            rows="8"
          />
        </div>

        <!-- Glyph Type -->
        <div v-if="input.type === 'Glyph'">
          <label v-if="input.caption" :for="index" class="form-label">
            {{ input.caption }}
          </label>
          <div class="input-group mb-3">
            <span class="input-group-text">
              <fa class="fs-1" :icon="['fad', input.value]"></fa>
            </span>
            <select
              class="form-control form-select"
              :name="index"
              :id="index"
              v-model="input.value"
            >
              <option value="baseball-bat-ball">Baseball</option>
              <option value="hockey-sticks">Hockey</option>
              <option value="tennis-ball">Tennis</option>
              <option value="volleyball">Volleyball</option>
              <option value="basketball">Basketball</option>
              <option value="goal-net">Goal</option>
              <option value="football-helmet">Helmet</option>
              <option value="football">Football</option>
              <option value="golf-flag-hole">Golf</option>
              <option value="person-biking">Cycling</option>
              <option value="person-running">Running</option>
              <option value="person-swimming">Swimming</option>
              <option value="table-tennis-paddle-ball">Table Tennis</option>
              <option value="sailboat">Boat</option>
              <option value="wave-pulse">Pulse</option>
              <option value="medal">Medal</option>
              <option value="flag-pennant">Flag</option>
              <option value="house-blank">Home</option>
              <option value="circle-star">Star</option>
              <option value="trophy-star">Trophy</option>
              <option value="sun">Sun</option>
              <option value="rocket">Rocket</option>
              <option value="robot">Robot</option>
              <option value="grip-dots">Dots</option>
            </select>
          </div>
        </div>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-outline-success" @click="clickSubmit">
          Update
        </button>
        <button class="btn btn-outline-danger" @click="$emit('close')">
          Close
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
export default {
  name: "PaneInput",
  components: {},
  props: ["panelID", "title", "icon", "modal", "files", "useFilename"],
  computed: {},
  data() {
    return {
      show: false,
      f: [],
      preview: [],
    };
  },
  methods: {
    selectImage(event, index, s3key, dbkey) {
      this.f[index] = [];
      this.f[index].currentImage = event.target.files.item(0);
      this.f[index].s3key = s3key;
      this.f[index].dbkey = dbkey;
      this.f[index].previewImage = URL.createObjectURL(
        this.f[index].currentImage
      );
      this.preview[index] = this.f[index].previewImage; //workaround a bug with v-if iterations
      this.f[index].progress = 0;
      this.f[index].message = "";
    },
    clickSubmit() {
      this.$emit("update:files", this.f);
      this.$emit("update:modal", this.modal);
      this.$emit("save");
      this.f = [];
      this.preview = [];
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
