<template>
  <pane-input
    panelID="panel-input"
    :title="modal.title"
    :icon="modal.icon"
    v-model:modal="modal.inputs"
    @close="clickModalClose()"
    @save="clickModalSave()"
  />

  <div id="manage" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="catalog"
          caption="Catalog"
          icon="book-open"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="users"
          caption="Users"
          icon="users"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="media"
          caption="Media"
          icon="photo-film-music"
          :isActive="false"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- Catalog Pane -->
        <pane-item id="catalog" :isActive="true">
          <pane-card title="Catalog Details" icon="square-info">
            <pane-card-body>
              <pane-card-item caption="Catalog ID" :value="catalogID">
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item caption="Name" :value="catalog.catalogName">
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Text',
                          key: 'catalogName',
                          value: catalog.catalogName,
                        },
                      ],
                      'Catalog Name',
                      'input-text'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item caption="Current Media Count" :value="mediaCount">
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Users Pane -->
        <pane-item id="users" :isActive="false">
          <pane-card title="Catalog Managers" icon="lock">
            <pane-card-body>
              <pane-card-item
                v-for="(user, index) in catalog.admins"
                :key="index"
                :caption="user.firstName + ' ' + user.lastName"
                :value="user.email"
              >
                <pane-card-item-button
                  @click="clickDeleteUser(index)"
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Catalogs Pane -->
        <pane-item id="media" :isActive="false">
          <pane-card title="Media" icon="photo-film-music">
            <table class="media-table table table-hover align-middle">
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Name</td>
                  <td>Venue</td>
                  <td>Scheduled Start</td>
                  <td>Scheduled End</td>
                  <td class="text-center">Published</td>
                  <td class="text-center">Live</td>
                  <td class="text-center">VOD</td>
                  <td class="controls-col p-0"></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(media, index) in catalog.media" :key="index">
                  <td>{{ media.mediaID }}</td>
                  <td>{{ media.mediaName }}</td>
                  <td>{{ media.mediaShortDescription }}</td>
                  <td>{{ formatDate(media.startDateUTC, true) }}</td>
                  <td>{{ formatDate(media.endDateUTC, true) }}</td>
                  <td class="text-center">
                    <fa
                      class="pe-1"
                      :icon="['fad', convertToBooleanGlyph(media.isPublished)]"
                    ></fa>
                  </td>
                  <td class="text-center">
                    <fa
                      class="pe-1"
                      :icon="['fad', convertToBooleanGlyph(media.isLive)]"
                    ></fa>
                  </td>
                  <td class="text-center">
                    <fa
                      class="pe-1"
                      :icon="['fad', convertToBooleanGlyph(media.isVOD)]"
                    ></fa>
                  </td>
                  <td class="align-middle text-center p-0">
                    <fa
                      class="row-btn"
                      @click="clickMediaViewLink(media.mediaID)"
                      :icon="['fad', 'tv']"
                    ></fa>
                    <fa
                      class="row-btn"
                      @click="clickMediaEditLink(media.mediaID)"
                      :icon="['fad', 'pen-line']"
                    ></fa>
                  </td>
                </tr>
              </tbody>
            </table>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";

import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";

import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";

import PaneInput from "@/components/ConsolePane/PaneInput";

import moment from "moment";

export default {
  name: "ManageCatalog",
  components: {
    PaneSidebar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
    PaneInput,
  },
  props: {},
  computed: {
    catalogID() {
      return this.$route.params.catalogID;
    },
    catalog() {
      return this.$store.getters.findCatalogByID(this.catalogID);
    },
    dataReady() {
      return typeof this.catalog !== "undefined";
    },
    mediaCount() {
      if (typeof this.catalog.media !== "undefined") {
        return this.catalog.media.length;
      }
      return 0;
    },
  },
  data() {
    return {
      modal: [],
    };
  },
  methods: {
    convertToBooleanGlyph: function (val) {
      if (val) {
        return "check";
      } else {
        return "xmark";
      }
    },
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
    clickMediaViewLink: function (mediaID) {
      this.$router.push("/view/" + mediaID);
    },
    clickMediaEditLink: function (mediaID) {
      this.$router.push("/manage/media/" + mediaID);
    },
    clickDeleteUser(index) {
      let userID = this.catalog.admins[index].uuid;
      let email = this.catalog.admins[index].email;
      let adminsArray = this.catalog.managementUserUUIDs.split(",");

      this.$swal({
        title: "Are you sure you want to remove " + email + "?",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.isConfirmed) {
          // remove userID from adminsArray
          let i = adminsArray.indexOf(userID);
          if (i !== -1) {
            adminsArray.splice(i, 1);
          }
          let formData = new FormData();
          formData.append("managementUserUUIDs", adminsArray.join(","));
          this.$store
            .dispatch("setCatalogField", {
              catalogID: this.catalog.catalogID,
              data: formData,
            })
            .then(
              () => {
                this.$swal({
                  title: "Success!",
                  text: "User '" + email + "' was removed.",
                  icon: "success",
                  confirmButtonText: "Yay!",
                });
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.error,
                  icon: "error",
                  cancelButtonText: "Oh no!",
                });
              }
            );

          // remove local instance
          this.catalog.admins.splice(index, 1);
        }
      });
    },
    clickModalActivate: function (modal, title, icon) {
      this.modal.title = title;
      this.modal.icon = icon;
      this.modal.inputs = modal;
      this.modal.files = [];
      this.$modal.show("panel-input");
    },
    clickModalClose: function () {
      this.$modal.hideAll();
    },
    clickModalSave: function () {
      let formData = new FormData();

      // process fields
      this.modal.inputs.forEach((i) => {
        if (i.type !== "File") {
          this.catalog[i.key] = i.value;
          formData.append(i.key, i.value);
        }
      });

      // commit data
      this.$store
        .dispatch("setCatalogField", {
          catalogID: this.catalog.catalogID,
          data: formData,
        })
        .then(
          (res) => {
            this.$swal({
              title: "Success!",
              text: res.result,
              icon: "success",
              confirmButtonText: "Yay!",
            });
          },
          (error) => {
            this.$swal({
              showCancelButton: true,
              showConfirmButton: false,
              title: "Error!",
              text: error.error,
              icon: "error",
              cancelButtonText: "Oh no!",
            });
          }
        );
      this.clickModalClose();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", "Manage Catalog");
    this.$store.dispatch("getCatalogAdminUsers", {
      catalogID: this.catalogID,
    });
  },
};
</script>

<style lang="scss" scoped></style>
