<template>
  <li
    id="user-nav-button"
    class="nav-item align-self-center pe-3"
    v-if="!user.sub"
  >
    <div class="btn btn-primary text-white px-3" @click="executeLogin">
      Log In
    </div>
  </li>
  <li class="nav-item dropdown align-self-center" v-if="user.sub">
    <a
      class="nav-link dropdown-toggle btn btn-primary text-white px-3 navbar-button-fixed"
      href="#"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="toggleMenu"
      @blur="clearMenu(200)"
    >
      <div class="d-inline-block align-self-center pe-1">
        {{ user.firstName }}
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right p-1" v-if="menuVisible">
      <p class="dropdown-header" href="#">Welcome {{ user.firstName }}</p>
      <hr class="dropdown-divider" />
      <router-link class="dropdown-item" to="/profile" @click="clearMenu"
        ><fa :icon="['fad', 'user']" class="pe-2 text-primary"></fa>
        My Profile
      </router-link>
      <a :href="wikiUrl" class="dropdown-item" @click="clearMenu">
        <fa :icon="['fad', 'circle-question']" class="pe-2 text-primary"></fa>
        Help
      </a>
      <router-link
        class="dropdown-item"
        to="/console"
        v-if="user.isAdmin"
        @click="clearMenu"
        ><fa :icon="['fad', 'cog']" class="pe-2 text-primary"></fa>
        Admin Console
      </router-link>
      <div
        class="dropdown-item"
        @click="
          clearMenu();
          executeLogout();
        "
      >
        <fa :icon="['fad', 'sign-out']" class="pe-2 text-primary"></fa>
        Logout
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UserNavButton",
  props: {},
  data() {
    return {
      wikiUrl: "",
      menuVisible: false,
    };
  },
  mounted() {
    this.wikiUrl = process.env.VUE_APP_WIKI_URL;
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    clearMenu(t = 0) {
      setTimeout(() => {
        this.menuVisible = false;
      }, t);
    },
    executeLogin() {
      //window.$sso.login();
      window.location.href = window.$sso.createLoginUrl({ redirectUri: "/" });
    },
    executeLogout() {
      window.$sso.logout();
    },
  },
  computed: mapState({
    user: (state) => state.user,
  }),
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  display: inline-block !important;
  position: absolute !important;
  right: 0 !important;
}
</style>
