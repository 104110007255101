<template>
  <div
    class="col-sm-2 console-sidebar p-0 animate__animated animate__fadeInLeft"
  >
    <ul class="nav navbar-nav me-auto mb-2 mb-lg-0">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaneSidebar",
  components: {},
  props: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
