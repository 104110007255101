<template>
  <div class="row px-3 pb-3">
    <div class="col-12">
      <div class="card">
        <div v-if="title" class="card-header">
          <fa v-if="icon" :icon="[fa, icon]" class="icon pr-2" />
          {{ title }}
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaneCard",
  components: {},
  props: {
    title: String,
    icon: String,
    fa: {
      type: String,
      default: "fad",
    },
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
