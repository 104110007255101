<template>
  <div
    class="row hero"
    :class="{ animate__animated: animate, animate__fadeIn: animate }"
  >
    <div
      :id="carouselID"
      class="carousel heroCarousel slide px-0"
      data-ride="carousel"
      data-pause="false"
    >
      <div class="carousel-inner">
        <div
          v-for="(item, index) in heroObjects"
          :key="index"
          class="carousel-item"
          :class="{ active: index === 0 }"
        >
          <img
            class="d-block w-100 carousel-img"
            :src="item.imageURL"
            :alt="item.mediaName"
          />
          <img
            class="d-block w-100 carousel-bg"
            :src="item.imageURL"
            :alt="item.mediaName"
          />
          <div class="carousel-caption d-none d-md-block">
            <div class="row upper d-flex">
              <div class="title d-inline-block">
                {{ item.mediaName }}
                <div
                  class="d-inline-block status-tag"
                  :class="{
                    'live-tag': item.isLive,
                    'vod-tag': item.isVod,
                  }"
                ></div>
              </div>
            </div>
            <div class="text">{{ renderDescription(item) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Bootstrap from "bootstrap";
import moment from "moment";

export default {
  name: "HeroCarousel",
  props: {
    heroObjects: {},
    interval: {
      type: Number,
      default: 5000,
    },
    carouselID: {
      type: String,
      default: "heroCarousel",
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    renderDescription: function (item) {
      if (item.mediaDescription === "") {
        return this.formatDate(item.startTime);
      } else {
        return (
          this.formatDate(item.startTime) + "  |  " + item.mediaDescription
        );
      }
    },
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
  },
  mounted() {
    const heroCarousel = document.querySelectorAll(".heroCarousel");
    let carouselObjects = [];

    heroCarousel.forEach((element, index) => {
      carouselObjects[index] = new Bootstrap.Carousel(element, {
        interval: Number(this.interval),
        pause: "false",
      });
      setTimeout(() => {
        carouselObjects[index].cycle(); // This fixes a bug where the carousel won't start.
      }, 200);
    });
  },
};
</script>

<style lang="scss" scoped></style>
