<template>
  <modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <span class="modal__title">{{ media.mediaName }}</span>
    <div class="infopane-wrapper">
      <div class="infopane-thumbnail">
        <img :src="media.thumbnailURL" />
      </div>
      <div class="infopane-description" v-html="media.mediaDescription"></div>
    </div>
  </modal>

  <div
    id="viewer"
    class="container-fluid px-0 w-100 overflow-hidden animate__animated animate__fadeIn"
    v-if="mediaDatasetReady"
  >
    <div class="row" v-if="!isPublished && !isViewerAdmin">
      <div class="viewer-error">
        <div>
          <fa :icon="['fad', 'exclamation-triangle']" class="text-primary"></fa>
          <p>This video is currently unavailable.</p>
        </div>
      </div>
    </div>

    <div class="row" v-if="(isPublished || isViewerAdmin) && !isLive && !isVod && !isStartingSoon">
      <div class="viewer-error">
        <div>
          <fa :icon="['fad', 'exclamation-triangle']" class="text-primary"></fa>
          <p>'{{ media.mediaName }}' has not started.</p>
          <p>It's currently scheduled for<br />{{ schedule }}</p>
        </div>
      </div>
    </div>

    <div class="row" v-if="(isPublished || isViewerAdmin) && (isLive || isVod || isStartingSoon)">
      <div class="viewer-wrapper">
        <plyr :media-id="mediaID" :media="media" :poster="poster"></plyr>
      </div>
    </div>

    <div
      class="row overflow-hidden"
      v-if="(isPublished || isViewerAdmin) && (isLive || isVod || isStartingSoon)"
    >
      <div class="info-wrapper animate__animated animate__fadeInUp">
        <div class="info" @click="showModal = true">
          <fa :icon="['fad', 'info-square']" class="btnInfo"></fa>
        </div>
        <div class="inner flex-grow-1">
          <div class="title">
            {{ media.mediaName }}
            <span v-if="!isPublished">
              <fa :icon="['fal', 'eye-slash']" class="unpublished-icon"></fa>
            </span>
          </div>
          <div class="schedule">
            <span :class="{ live: media.isLive, soon: media.isStartingSoon }">
              {{ formatSchedule(schedule) }}
            </span>
          </div>
        </div>
        <!--        <div class="currentUsers" v-if="formatViewCounter">-->
        <!--          <fa :icon="['fad', 'eye']" class=""></fa>-->
        <!--          <span class="count px-3">{{ counter }}</span>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";

import plyr from "../components/plyr.vue";

export default {
  name: "Viewer",
  components: {
    plyr,
  },
  data() {
    return {
      player: {},
      hls: {},
      playerInitialized: false,
      mediaDatasetReady: false,
      showModal: false,
      showCounter: false,
    };
  },
  computed: {
    media() {
      return this.$store.getters.getCurrentMedia;
    },
    mediaID() {
      return this.$route.params.mediaID;
    },
    poster() {
      return this.media.heroImageURL;
    },
    schedule() {
      return this.formatDate(this.media.startDateUTC);
    },
    isLive() {
      return this.media.isLive;
    },
    isVod() {
      return this.media.isVod;
    },
    isStartingSoon() {
      return this.media.isStartingSoon;
    },
    isPublished() {
      return this.media.isPublished;
    },
    counter() {
      if (this.isLive) {
        return this.media.currentUniqueViewers;
      } else {
        return this.media.totalUniqueViews;
      }
    },
    isViewerAdmin() {
      const ctxUser = this.$store.getters.getUser;
      if (ctxUser.isAdmin) {
        console.log("User is admin - show unpublished content");
        return true;
      }
      return false;
    }
  },
  methods: {
    formatSchedule(item) {
      if (this.media.isLive) {
        return "LIVE NOW";
      } else if (this.media.isStartingSoon) {
        return "STREAM STARTING SOON";
      } else if (this.media.isVod) {
        return "Streamed on: " + this.formatDate(this.media.startDateUTC);
      }
      if (typeof item !== "undefined") {
        return this.formatDate(item.startDateUTC);
      }
      return "";
    },
    formatDate: function (date, time = true) {
      if (typeof date !== "undefined") {
        if (time) {
          return moment(date + "+00:00")
            .local()
            .format("Do MMM YYYY - h:mm a");
        }
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY");
      }
    },
    formatViewCounter: function () {
      return !!(this.isLive || this.isVod);
    },
    showUserError: (title, message, duration = 5000) => {
      notify({
        title: title,
        text: message,
        position: "bottom right",
        type: "error",
        duration: duration,
      });
    },
  },
  mounted() {
    this.$store
      .dispatch("setCurrentMediaDataset", this.mediaID)
      .then(async () => {
        this.mediaDatasetReady = true;
      });
  },
};
</script>

<style lang="scss" scoped>
#viewer {
  background: black !important;
}

::v-deep(.modal-content) {
  width: 50% !important;
}

.unpublished-icon {
  padding-left: 6px;
  padding-bottom: 2.5px;
  font-size: 0.6em;
}
</style>
