<template>
  <pane-input
    panelID="panel-input"
    :title="modal.title"
    :icon="modal.icon"
    v-model:modal="modal.inputs"
    v-model:files="modal.files"
    @close="clickModalClose()"
    @save="clickModalSave()"
  />

  <div id="manage" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="general"
          caption="Organisation"
          icon="sitemap"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="users"
          caption="Users"
          icon="users"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="channels"
          caption="Channels"
          icon="tv-retro"
          :isActive="false"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- Organisation Pane -->
        <pane-item id="general" :isActive="true">
          <pane-card title="Organisation Details" icon="square-info">
            <pane-card-body>
              <pane-card-item caption="Organisation ID" :value="orgURL">
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="URL"
                :value="
                  'https://anywherelive.tv/<strong>' +
                  organisation.vanityUrl +
                  '</strong>'
                "
              >
                <pane-card-item-button
                  @click="
                    this.$swal({
                      text: 'Please contact Anywhere Live support to change your custom URL.',
                      icon: 'info',
                    })
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Name"
                :value="organisation.organisationName"
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Text',
                          key: 'organisationName',
                          value: organisation.organisationName,
                        },
                      ],
                      'Organisation Name',
                      'input-text'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item caption="Logo" :value="organisation.logoURL">
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'File',
                          key: 'logoURL',
                          s3key: 'org/' + orgURL + '/logo',
                        },
                      ],
                      'Logo',
                      'photo-film'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Branding Colour"
                :value="
                  '<div style=&quot; color: ' +
                  organisation.organisationColour +
                  '&quot;><strong>' +
                  organisation.organisationColour +
                  '</strong></div>'
                "
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Colour',
                          key: 'organisationColour',
                          value: organisation.organisationColour,
                        },
                      ],
                      'Brand Colour',
                      'palette'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Current Channel Count"
                :value="channelCount + ' of 5'"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
          <pane-card title="Home Page Configuration" icon="house">
            <pane-card-body>
              <pane-card-item
                caption="Featured Items"
                :value="' ' + featuredItemsCount"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Number of days shown for Live & Upcoming"
                :value="organisation.liveAndUpcomingDays + ' day(s)'"
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Number',
                          key: 'liveAndUpcomingDays',
                          value: organisation.liveAndUpcomingDays,
                          min: 1,
                          max: 30,
                        },
                      ],
                      'Upcoming Live Days',
                      'calendar'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Users Pane -->
        <pane-item id="users" :isActive="false">
          <pane-card title="Organisation Managers" icon="lock">
            <pane-card-body>
              <pane-card-item
                v-for="(user, index) in organisation.admins"
                :key="index"
                :caption="user.firstName + ' ' + user.lastName"
                :value="user.email"
              >
                <pane-card-item-button
                  @click="clickDeleteUser(index)"
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Channels Pane -->
        <pane-item id="channels" :isActive="false">
          <pane-card title="Channels" icon="tv-retro">
            <pane-card-body>
              <pane-card-item
                v-for="(channel, index) in organisation.channels"
                :key="index"
                :caption="channel.channelName"
                :value="channel.catalogs.length + ' catalogs'"
              >
                <pane-card-item-button
                  @click="clickChannelLink(channel.channelID)"
                  icon="chevrons-right"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
                <pane-card-item-button
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";

import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";

import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";

import PaneInput from "@/components/ConsolePane/PaneInput";

export default {
  name: "ManageOrganisation",
  components: {
    PaneSidebar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
    PaneInput,
  },
  props: {},
  computed: {
    orgURL() {
      return this.$route.params.orgID;
    },
    organisation() {
      return this.$store.getters.findOrganisationByID(this.orgURL);
    },
    dataReady() {
      return typeof this.organisation !== "undefined";
    },
    channelCount() {
      if (typeof this.organisation.channels !== "undefined") {
        return this.organisation.channels.length;
      }
      return 0;
    },
    featuredItemsCount() {
      if (typeof this.organisation.featured !== "undefined") {
        if (this.organisation.featured.length < 1) {
          return 0;
        }
        return this.organisation.featured.length;
      }
      return 0;
    },
  },
  data() {
    return {
      modal: [],
    };
  },
  methods: {
    clickDeleteUser(index) {
      let userID = this.organisation.admins[index].uuid;
      let email = this.organisation.admins[index].email;
      let adminsArray = this.organisation.managementUserUUIDs.split(",");

      this.$swal({
        title: "Are you sure you want to remove " + email + "?",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.isConfirmed) {
          // remove userID from adminsArray
          let i = adminsArray.indexOf(userID);
          if (i !== -1) {
            adminsArray.splice(i, 1);
          }
          let formData = new FormData();
          formData.append("managementUserUUIDs", adminsArray.join(","));
          this.$store
            .dispatch("setOrganisationField", {
              organisationID: this.organisation.organisationID,
              data: formData,
            })
            .then(
              () => {
                this.$swal({
                  title: "Success!",
                  text: "User '" + email + "' was removed.",
                  icon: "success",
                  confirmButtonText: "Yay!",
                });
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.error,
                  icon: "error",
                  cancelButtonText: "Oh no!",
                });
              }
            );

          // remove local instance
          this.organisation.admins.splice(index, 1);
        }
      });
    },
    clickChannelLink: function (channelID) {
      this.$router.push("/manage/channel/" + channelID);
    },
    clickModalActivate: function (modal, title, icon) {
      this.modal.title = title;
      this.modal.icon = icon;
      this.modal.inputs = modal;
      this.modal.files = [];
      this.$modal.show("panel-input");
    },
    clickModalClose: function () {
      this.$modal.hideAll();
    },
    clickModalSave: function () {
      let formData = new FormData();

      // process files
      this.modal.files.forEach((f, i) => {
        formData.append("file", f.currentImage);
        formData.append("FILES_" + i + "_S3KEY", f.s3key);
        formData.append("FILES_" + i + "_DBKEY", f.dbkey);
      });

      // process fields
      this.modal.inputs.forEach((i) => {
        if (i.type !== "File") {
          this.organisation[i.key] = i.value;
          formData.append(i.key, i.value);
        }
      });

      // commit data
      this.$store
        .dispatch("setOrganisationField", {
          organisationID: this.organisation.organisationID,
          data: formData,
        })
        .then(
          (res) => {
            // update local model with S3 response
            res.filekeys.forEach((f) => {
              this.organisation[f.key] = f.s3url;
            });

            this.$swal({
              title: "Success!",
              text: res.result,
              icon: "success",
              confirmButtonText: "Yay!",
            });
          },
          (error) => {
            this.$swal({
              showCancelButton: true,
              showConfirmButton: false,
              title: "Error!",
              text: error.error,
              icon: "error",
              cancelButtonText: "Oh no!",
            });
          }
        );
      this.clickModalClose();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", "Manage Organisation");
    this.$store.dispatch("getOrgAdminUsers", {
      organisationID: this.orgURL,
    });
  },
};
</script>

<style lang="scss" scoped></style>
