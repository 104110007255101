<template>
  <div
    id="organisation"
    class="container-fluid overflow-hidden primary-catalog-display"
  >
    <organisation-banner
      :organisationName="organisation.organisationName"
      :logoURL="organisation.logoURL"
      :organisationColour="organisation.organisationColour"
    ></organisation-banner>

    <hero-carousel
      v-if="typeof organisation.featured != 'undefined'"
      :heroObjects="organisation.featured"
      :carouselID="'car-' + organisation.organisationID"
      :interval="5000"
    ></hero-carousel>

    <!-- Live and Upcoming - Whole organisation -->
    <catalog-row
      :catalogid="'liveAndUpcoming'"
      :catalog-name="'Live and Upcoming'"
      :catalog-objects="organisation.liverow"
      sort-order="asc"
      :show-unpublished="false"
    ></catalog-row>

    <!-- Channels -->
    <div v-for="(channel, index) in organisation.channels" :key="index">
      <ChannelHeader
        :channelName="channel.channelName"
        :channelID="channel.channelID"
        :linkable="false"
        :icon="channel.channelGlyph"
        :organisationColour="organisation.organisationColour"
      ></ChannelHeader>
      <catalog-row
        v-for="(catalog, index) in channel.catalogs"
        :key="index"
        :catalogid="catalog.catalogID"
        :catalog-name="catalog.catalogName"
        :catalog-objects="catalog.media"
        sort-order="desc"
        :show-unpublished="false"
      ></catalog-row>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import HeroCarousel from "@/components/HeroCarousel";
import OrganisationBanner from "@/components/OrganisationBanner";
import ChannelHeader from "@/components/ChannelHeader";
import CatalogRow from "@/components/CatalogRow";

export default {
  name: "Organisation",
  components: {
    HeroCarousel,
    OrganisationBanner,
    ChannelHeader,
    CatalogRow,
  },
  mounted() {
    console.log("Organisation: " + this.orgURL);
  },
  computed: {
    orgURL() {
      return this.$route.params.orgID;
    },
    organisation() {
      let data = this.$store.getters.findOrganisationByVanity(this.orgURL);
      if (typeof data.organisationName !== "undefined") {
        this.$store.commit("setPageTitle", data.organisationName);
      }
      return data;
    },
  },
};
</script>

<style scoped></style>
