<template>
  <div class="row px-0">
    <div class="channel-header w-100" :style="barColour()">
      <div
        class="header-title"
        :class="{ nolink: !linkable }"
        @click="clickHeader(channelID)"
        :style="titleColour()"
      >
        <fa class="pe-2" :icon="['fad', icon]"></fa>
        {{ channelName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChannelHeader",
  props: {
    channelName: {
      type: String,
    },
    channelID: {
      type: String,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "chevrons-right",
    },
    organisationColour: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    clickHeader: function (channelURL) {
      if (this.linkable) {
        this.$router.push("/" + channelURL);
      }
    },
    titleColour: function () {
      if (this.organisationColour) {
        return { color: this.organisationColour };
      }
      return {};
    },
    barColour: function () {
      if (this.organisationColour) {
        let col = lightenHex(this.organisationColour, 0.7);
        return { "border-bottom": "3px solid " + col };
      }
      return {};
    },
  },
};

function lightenHex(col, light) {
  let r = parseInt(col.substr(1, 2), 16);
  let g = parseInt(col.substr(3, 2), 16);
  let b = parseInt(col.substr(5, 2), 16);

  if (light < 0) {
    r = (1 + light) * r;
    g = (1 + light) * g;
    b = (1 + light) * b;
  } else {
    r = (1 - light) * r + light * 255;
    g = (1 - light) * g + light * 255;
    b = (1 - light) * b + light * 255;
  }

  return "#" + hex2(r) + hex2(g) + hex2(b);
}

function hex2(c) {
  c = Math.round(c);
  if (c < 0) c = 0;
  if (c > 255) c = 255;

  let s = c.toString(16);
  if (s.length < 2) s = "0" + s;

  return s;
}
</script>

<style lang="scss" scoped></style>
