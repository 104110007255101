<template>
  <vue-plyr ref="plyr" :options="playerOptions">
    <video
      ref="video"
      :id="mediaID"
      :poster="poster"
      :data-plyr-config="config"
      :src="source"
      :playsinline="playsinline"
    ></video>
  </vue-plyr>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import Hls from "hls.js";

let plyrOptions_live = [
  "play-large",
  "play",
  "mute",
  "volume",
  "settings",
  "fullscreen",
  "pip",
  "airplay",
];
let plyrOptions_vod = [
  "play-large",
  "play",
  "progress",
  "current-time",
  "mute",
  "volume",
  "settings",
  "fullscreen",
  "pip",
  "airplay",
];

export default {
  name: "plyr",
  components: {
    VuePlyr,
  },
  props: ["mediaID", "media", "poster"],
  computed: {
    playerOptions() {
      if (this.isLive) {
        return { controls: plyrOptions_live, settings: ["quality"] };
      }
      return { controls: plyrOptions_vod, settings: ["quality"] };
    },
    url() {
      return this.media.streamURL;
    },
    isLive() {
      return this.media.isLive;
    },
  },
  data() {
    return {
      player: {},
      video: {},
      hls: {},
      source: "",
      playsinline: false,
      playerInitialized: false,
      mediaDatasetReady: false,
      config: { autoplay: true },
    };
  },
  methods: {
    showUserError: (title, message, duration = 5000) => {
      notify({
        title: title,
        text: message,
        position: "bottom right",
        type: "error",
        duration: duration,
      });
    },
  },
  mounted() {
    console.log("Anywhere Live player started...");
    this.player = this.$refs.plyr.player;

    // Video
    this.$store.commit("setPageTitle", this.media.mediaName);
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.loadSource(this.url);
      this.hls.attachMedia(this.player.media);

      this.hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log("Video and HLS bind success");
      });

      this.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          "HLS manifest loaded. Found " + data.levels.length + " quality level"
        );

        // once media is ready - load custom play button
        let ePlayButtonParent = document.querySelector(
          ".plyr__control--overlaid"
        );
        ePlayButtonParent.removeChild(ePlayButtonParent.firstChild);
        let svg = document.createElement("img");
        svg.src = require("../assets/viewer/lrgPlayIcon.svg");
        ePlayButtonParent.appendChild(svg);
      });

      let objHLS = this.hls;
      let objAlert = this.showUserError;
      this.hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.log("fatal network error");
              objHLS.startLoad();
              objAlert(
                "Network Error",
                "A network error occurred. Trying to reconnect..."
              );
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log("HLS fatal media error");
              objHLS.recoverMediaError();
              objAlert(
                "Media Error",
                "A network error occurred. Trying to recover...",
                -1
              );
              break;
            default:
              objHLS.destroy();
              objAlert(
                "Playback Error",
                "Video playback failed. Please try again later.",
                -1
              );
              break;
          }
        }
      });
    } else {
      console.log("HLS MSE is not supported by this browser");

      // Fix for iOS not supporting Media Source Extensions (MSE)
      this.video = this.$refs.video;
      if (this.video.canPlayType("application/vnd.apple.mpegurl")) {
        console.log("Apple HLS support found.");

        this.source = this.url;
        this.playsinline = true;
        console.log(this.$refs.plyr);

        let ePlayButtonParent = document.querySelector(
          ".plyr__control--overlaid"
        );
        ePlayButtonParent.removeChild(ePlayButtonParent.firstChild);
        let svg = document.createElement("img");
        svg.src = require("../assets/viewer/lrgPlayIcon.svg");
        ePlayButtonParent.appendChild(svg);
        // End fix for iOS
      } else {
        this.showUserError(
          "Playback Error",
          "Video playback is not supported by your browser.",
          -1
        );
      }
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
#viewer {
  background: black !important;
}

::v-deep(.modal-content) {
  width: 50% !important;
}
</style>
