<template>
  <pane-input
    panelID="panel-input"
    :title="modal.title"
    :icon="modal.icon"
    v-model:modal="modal.inputs"
    @close="clickModalClose()"
    @save="clickModalSave()"
  />

  <div id="manage" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="channel"
          caption="Channel"
          icon="tv-retro"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="users"
          caption="Users"
          icon="users"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="catalogs"
          caption="Catalogs"
          icon="book-open"
          :isActive="false"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- Channel Pane -->
        <pane-item id="channel" :isActive="true">
          <pane-card title="Channel Details" icon="square-info">
            <pane-card-body>
              <pane-card-item caption="Channel ID" :value="channelID">
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item caption="Name" :value="channel.channelName">
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Text',
                          key: 'channelName',
                          value: channel.channelName,
                        },
                      ],
                      'Channel Name',
                      'input-text'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Channel Icon"
                :glyph="channel.channelGlyph"
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Glyph',
                          key: 'channelGlyph',
                          value: channel.channelGlyph,
                        },
                      ],
                      'Channel Icon',
                      'icons'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item caption="Shown on Home" :value="shownOnHome">
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Current Catalog Count"
                :value="catalogCount + ' of 10'"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Users Pane -->
        <pane-item id="users" :isActive="false">
          <pane-card title="Channel Managers" icon="lock">
            <pane-card-body>
              <pane-card-item
                v-for="(user, index) in channel.admins"
                :key="index"
                :caption="user.firstName + ' ' + user.lastName"
                :value="user.email"
              >
                <pane-card-item-button
                  @click="clickDeleteUser(index)"
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Catalogs Pane -->
        <pane-item id="catalogs" :isActive="false">
          <pane-card title="Catalogs" icon="tv-retro">
            <pane-card-body>
              <pane-card-item
                v-for="(catalog, index) in channel.catalogs"
                :key="index"
                :caption="catalog.catalogName"
                :value="catalog.media.length + ' videos'"
              >
                <pane-card-item-button
                  @click="clickCatalogLink(catalog.catalogID)"
                  icon="chevrons-right"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
                <pane-card-item-button
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";

import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";

import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";

import PaneInput from "@/components/ConsolePane/PaneInput";

export default {
  name: "ManageChannel",
  components: {
    PaneSidebar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
    PaneInput,
  },
  props: {},
  computed: {
    channelID() {
      return this.$route.params.channelID;
    },
    channel() {
      return this.$store.getters.findChannelByID(this.channelID);
    },
    dataReady() {
      return typeof this.channel !== "undefined";
    },
    shownOnHome() {
      if (this.channel.showOnHome) {
        return "True";
      }
      return "False";
    },
    catalogCount() {
      if (typeof this.channel.catalogs !== "undefined") {
        return this.channel.catalogs.length;
      }
      return 0;
    },
  },
  data() {
    return {
      modal: [],
    };
  },
  methods: {
    clickCatalogLink: function (catalogID) {
      this.$router.push("/manage/catalog/" + catalogID);
    },
    clickDeleteUser(index) {
      let userID = this.channel.admins[index].uuid;
      let email = this.channel.admins[index].email;
      let adminsArray = this.channel.managementUserUUIDs.split(",");

      this.$swal({
        title: "Are you sure you want to remove " + email + "?",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.isConfirmed) {
          // remove userID from adminsArray
          let i = adminsArray.indexOf(userID);
          if (i !== -1) {
            adminsArray.splice(i, 1);
          }
          let formData = new FormData();
          formData.append("managementUserUUIDs", adminsArray.join(","));
          this.$store
            .dispatch("setChannelField", {
              channelID: this.channel.channelID,
              data: formData,
            })
            .then(
              () => {
                this.$swal({
                  title: "Success!",
                  text: "User '" + email + "' was removed.",
                  icon: "success",
                  confirmButtonText: "Yay!",
                });
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.error,
                  icon: "error",
                  cancelButtonText: "Oh no!",
                });
              }
            );

          // remove local instance
          this.channel.admins.splice(index, 1);
        }
      });
    },
    clickModalActivate: function (modal, title, icon) {
      this.modal.title = title;
      this.modal.icon = icon;
      this.modal.inputs = modal;
      this.modal.files = [];
      this.$modal.show("panel-input");
    },
    clickModalClose: function () {
      this.$modal.hideAll();
    },
    clickModalSave: function () {
      let formData = new FormData();

      // process fields
      this.modal.inputs.forEach((i) => {
        if (i.type !== "File") {
          this.channel[i.key] = i.value;
          formData.append(i.key, i.value);
        }
      });

      // commit data
      this.$store
        .dispatch("setChannelField", {
          channelID: this.channel.channelID,
          data: formData,
        })
        .then(
          (res) => {
            this.$swal({
              title: "Success!",
              text: res.result,
              icon: "success",
              confirmButtonText: "Yay!",
            });
          },
          (error) => {
            this.$swal({
              showCancelButton: true,
              showConfirmButton: false,
              title: "Error!",
              text: error.error,
              icon: "error",
              cancelButtonText: "Oh no!",
            });
          }
        );
      this.clickModalClose();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", "Manage Channel");
    this.$store.dispatch("getChannelAdminUsers", {
      channelID: this.channelID,
    });
  },
};
</script>

<style lang="scss" scoped></style>
