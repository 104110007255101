<template>
  <pane-input
    panelID="panel-input"
    :title="modal.title"
    :icon="modal.icon"
    v-model:modal="modal.inputs"
    v-model:files="modal.files"
    @close="clickModalClose()"
    @save="clickModalSave()"
  />

  <div id="edit" class="container-fluid console-wrapper text-dark">
    <div class="row h-100">
      <pane-sidebar>
        <pane-sidebar-item
          id="general"
          caption="General"
          icon="photo-film-music"
          :isActive="true"
        ></pane-sidebar-item>
        <pane-sidebar-item
          @click="usersInit"
          id="users"
          caption="Media Managers"
          icon="users"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="assets"
          caption="Assets"
          icon="folder-image"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="cloud"
          caption="Cloud"
          icon="cloud"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="scoring"
          caption="Scoring"
          icon="whistle"
          :isActive="false"
        ></pane-sidebar-item>
        <pane-sidebar-item
          id="metrics"
          caption="Metrics"
          icon="chart-bar"
          :isActive="false"
        ></pane-sidebar-item>
      </pane-sidebar>

      <pane v-if="dataReady">
        <!-- General Pane -->
        <pane-item id="general" :isActive="true">
          <pane-card title="Media Details" icon="square-info">
            <div class="row">
              <div class="col-12">
                <pane-card-body>
                  <pane-card-item caption="Media ID" :value="mediaID">
                    <pane-card-item-button
                      icon="pen-slash"
                      :enabled="false"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item caption="Name" :value="media.mediaName">
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'Text',
                              key: 'mediaName',
                              value: media.mediaName,
                            },
                          ],
                          'Media Name',
                          'input-text'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Venue"
                    :value="media.mediaShortDescription"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'Text',
                              key: 'mediaShortDescription',
                              value: media.mediaShortDescription,
                            },
                          ],
                          'Venue',
                          'goal-net'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item caption="Visibility" :value="published">
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: 'Publish?',
                              type: 'Check',
                              key: 'isPublished',
                              value: boolToCheck(media.isPublished),
                            },
                          ],
                          'Visibility',
                          'megaphone'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
                <pane-card-body>
                  <pane-card-item caption="Channel" :value="'channel'">
                    <pane-card-item-button
                      icon="pen-slash"
                      :enabled="false"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item caption="Catalog" :value="media.catalogID">
                    <pane-card-item-button
                      icon="pen-slash"
                      :enabled="false"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
                <pane-card-body>
                  <pane-card-item
                    caption="Stream Scheduled Start"
                    :value="formatDate(media.startDateUTC)"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption:
                                'Note: Times are in your local timezone.',
                              type: 'Date',
                              key: 'startDateUTC',
                              value: dateToLocal(media.startDateUTC),
                            },
                          ],
                          'Stream Scheduled Start',
                          'calendar'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Stream Scheduled End"
                    :value="formatDate(media.endDateUTC)"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption:
                                'Note: Times are in your local timezone.',
                              type: 'Date',
                              key: 'endDateUTC',
                              value: dateToLocal(media.endDateUTC),
                            },
                          ],
                          'Stream Scheduled End',
                          'calendar'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Go Live Time"
                    :value="' ' + formatDate(media.goLiveTime)"
                  >
                    <pane-card-item-button
                      icon="pen-slash"
                      :enabled="false"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="End Stream Time"
                    :value="' ' + formatDate(media.endedStreamTime)"
                  >
                    <pane-card-item-button
                      icon="pen-slash"
                      :enabled="false"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
              </div>
            </div>
            <div class="row px-4 py-3">
              <div
                class="description-box d-flex p-0 justify-content-between align-items-center"
              >
                <p class="p-2 flex-grow-1" v-html="media.mediaDescription"></p>
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: 'Note: HTML Tags can be used',
                          type: 'Textarea',
                          key: 'mediaDescription',
                          value: media.mediaDescription,
                        },
                      ],
                      'Description',
                      'comments'
                    )
                  "
                  class="ps-0"
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                  :nopadding="true"
                ></pane-card-item-button>
              </div>
            </div>
          </pane-card>
        </pane-item>

        <!-- Users Pane -->
        <pane-item id="users" :isActive="false">
          <pane-card title="Media Managers" icon="lock">
            <pane-card-body
              v-if="
                typeof media.admins === 'undefined' || media.admins.length < 1
              "
            >
              No users assigned.
            </pane-card-body>
            <pane-card-body
              v-if="
                typeof media.admins !== 'undefined' && media.admins.length > 0
              "
            >
              <pane-card-item
                v-for="(user, index) in media.admins"
                :key="index"
                :caption="user.firstName + ' ' + user.lastName"
                :value="user.email"
              >
                <pane-card-item-button
                  @click="usersClickDeleteUser(index)"
                  icon="trash-can"
                  :enabled="true"
                  type="delete"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Assets Pane -->
        <pane-item id="assets" :isActive="false">
          <pane-card title="Assets" icon="images">
            <div class="row">
              <div class="col-2 ps-4">
                <div class="row">
                  <pane-card-body>
                    <img
                      class="card thumb-preview"
                      :src="media.thumbnailURL"
                      :alt="media.mediaName"
                    />
                  </pane-card-body>
                </div>
                <div class="row">
                  <pane-card-body>
                    <img
                      class="card thumb-preview"
                      :src="media.heroImageURL"
                      :alt="media.mediaName"
                    />
                  </pane-card-body>
                </div>
              </div>
              <div class="col-10">
                <pane-card-body>
                  <pane-card-item
                    caption="Thumbnail"
                    :value="media.thumbnailURL"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'File',
                              key: 'thumbnailURL',
                              s3key: 'media/' + mediaID + '/assets',
                            },
                          ],
                          'Upload Thumbnail',
                          'image-polaroid'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item
                    caption="Hero Image"
                    :value="media.heroImageURL"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'File',
                              key: 'heroImageURL',
                              s3key: 'media/' + mediaID + '/assets',
                            },
                          ],
                          'Upload Hero Image',
                          'image-polaroid'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
                <pane-card-body>
                  <pane-card-item
                    caption="Stream URL"
                    :value="' ' + media.streamURL"
                  >
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'Text',
                              key: 'streamURL',
                              value: media.streamURL,
                            },
                          ],
                          'Steam URL',
                          'link'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                  <pane-card-item caption="VOD URL" :value="' ' + media.vodURL">
                    <pane-card-item-button
                      @click="
                        clickModalActivate(
                          [
                            {
                              caption: '',
                              type: 'Text',
                              key: 'vodURL',
                              value: media.vodURL,
                            },
                          ],
                          'On Demand URL',
                          'link'
                        )
                      "
                      icon="pen-line"
                      :enabled="true"
                      type="edit"
                    ></pane-card-item-button>
                  </pane-card-item>
                </pane-card-body>
              </div>
            </div>
          </pane-card>
        </pane-item>

        <!-- Cloud Pane -->
        <pane-item id="cloud" :isActive="false">
          <pane-card title="AWS Configuration" icon="aws" fa="fab">
            <pane-card-body>
              <pane-card-item
                caption="CloudFormation Template"
                :value="cloudFormatAWSTemplate"
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Select',
                          key: 'awsTemplateID',
                          value: media.awsTemplateID,
                          options: [
                            { text: 'Single Pipeline', value: 1 },
                            { text: 'RTMP Pipeline', value: 2 },
                          ],
                        },
                      ],
                      'CloudFormation Template',
                      'forklift'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Template Parameters"
                :value="
                  ' ' + cloudFormatGenericValue(media.awsTemplateParameters)
                "
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: 'Note: Must be valid JSON',
                          type: 'AwsParams',
                          key: 'awsTemplateParameters',
                          value: media.awsTemplateParameters,
                        },
                      ],
                      'AWS Template Parameters',
                      'alien-8bit'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
          <pane-card title="CloudFormation" icon="forklift">
            <pane-card-body>
              <pane-card-item
                caption="CloudFormation Status"
                :value="' ' + cloudFormatCloudFormationStatus"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="CloudFormation Stack ID"
                :value="
                  ' ' + cloudFormatGenericValue(media.cloudFormationStackID)
                "
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="CloudFormation Error"
                :value="
                  ' ' + cloudFormatGenericValue(media.cloudFormationError)
                "
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
          <pane-card title="MediaLive" icon="photo-film-music">
            <pane-card-body>
              <pane-card-item
                caption="MediaLive Status"
                :value="' ' + cloudFormatMediaLiveStatus"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Harvest State"
                :value="' ' + cloudFormatGenericValue(media.harvestState)"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Harvest ID"
                :value="' ' + cloudFormatGenericValue(media.harvestID)"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
          <pane-card title="CloudFront" icon="chart-network">
            <pane-card-body>
              <pane-card-item
                caption="CloudFront Status"
                :value="' ' + cloudFormatCloudFrontStatus"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="CloudFront Error"
                :value="' ' + cloudFormatGenericValue(media.cloudFrontError)"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Domain Name"
                :value="
                  ' ' + cloudFormatGenericValue(media.cloudFrontDomainName)
                "
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Scoring Pane -->
        <pane-item id="scoring" :isActive="false">
          <pane-card title="Scoring Configuration" icon="whistle">
            <pane-card-body>
              <pane-card-item
                caption="Scoring Enabled"
                :value="' ' + boolToText(media.scoringEnabled)"
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: 'Enable Scoring?',
                          type: 'Check',
                          key: 'scoringEnabled',
                          value: boolToCheck(media.scoringEnabled),
                        },
                      ],
                      'Enable Scoring',
                      'whistle'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Scoring Type"
                :value="' ' + scoringFormatScoreType"
              >
                <pane-card-item-button
                  @click="
                    clickModalActivate(
                      [
                        {
                          caption: '',
                          type: 'Select',
                          key: 'scoringTypeID',
                          value: media.scoringTypeID,
                          options: [
                            { text: 'Generic', value: 0 },
                            { text: 'Rugby', value: 1 },
                            { text: 'Basketball', value: 2 },
                          ],
                        },
                      ],
                      'Scoring Type',
                      'whistle'
                    )
                  "
                  icon="pen-line"
                  :enabled="true"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>

        <!-- Metrics Pane -->
        <pane-item id="metrics" :isActive="false">
          <pane-card title="Metrics" icon="glasses">
            <pane-card-body>
              <pane-card-item
                caption="Peak Viewers"
                :value="' ' + media.peakViewers"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Unique Views"
                :value="' ' + media.totalUniqueViews"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
              <pane-card-item
                caption="Current Unique Views"
                :value="' ' + media.currentUniqueViewers"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
          <pane-card title="PanelBear" icon="teddy-bear">
            <pane-card-body>
              <pane-card-item
                caption="Panelbear Metrics"
                :value="metricsGetPanelbearURL()"
              >
                <pane-card-item-button
                  icon="pen-slash"
                  :enabled="false"
                  type="edit"
                ></pane-card-item-button>
              </pane-card-item>
            </pane-card-body>
          </pane-card>
        </pane-item>
      </pane>
    </div>
  </div>
</template>

<script>
import PaneSidebar from "@/components/ConsolePane/PaneSidebar";
import PaneSidebarItem from "@/components/ConsolePane/PaneSidebarItem";

import Pane from "@/components/ConsolePane/Pane";
import PaneItem from "@/components/ConsolePane/PaneItem";

import PaneCard from "@/components/ConsolePane/PaneCard";
import PaneCardBody from "@/components/ConsolePane/PaneCardBody";
import PaneCardItem from "@/components/ConsolePane/PaneCardItem";
import PaneCardItemButton from "@/components/ConsolePane/PaneCardItemBtn";

import PaneInput from "@/components/ConsolePane/PaneInput";
import moment from "moment";

export default {
  name: "AddEditMedia",
  components: {
    PaneSidebar,
    PaneSidebarItem,
    Pane,
    PaneItem,
    PaneCard,
    PaneCardBody,
    PaneCardItem,
    PaneCardItemButton,
    PaneInput,
  },
  props: {},
  computed: {
    mediaID() {
      return this.$route.params.mediaID;
    },
    media() {
      return this.$store.getters.findMediaByID(this.mediaID);
    },
    dataReady() {
      return typeof this.media !== "undefined";
    },
    published() {
      if (this.media.isPublished) {
        return "Published";
      } else {
        return "Hidden";
      }
    },
    cloudFormatAWSTemplate() {
      switch (this.media.awsTemplateID) {
        case 1:
          return "Single Pipeline";
        case 2:
          return "RTMP Pipeline";
        default:
          return "Unknown";
      }
    },
    cloudFormatCloudFormationStatus() {
      switch (this.media.cloudFormationStatus) {
        case 0:
          return "Pending";
        case 1:
          return "Deploying";
        case 2:
          return "Deployed";
        case 3:
          return "Failed";
        case 4:
          return "Ready";
        case 5:
          return "Delete Queued";
        case 6:
          return "Deleted";
        default:
          return "Unknown";
      }
    },
    cloudFormatMediaLiveStatus() {
      switch (this.media.mediaLiveStatus) {
        case -1:
          return "Failed";
        case 0:
          return "Pending";
        case 1:
          return "Starting";
        case 2:
          return "Running";
        case 3:
          return "Stop Queued";
        case 4:
          return "Stopping";
        case 5:
          return "Stopped";
        default:
          return "Unknown";
      }
    },
    cloudFormatCloudFrontStatus() {
      switch (this.media.cloudFrontStatus) {
        case -1:
          return "Failed";
        case 0:
          return "Pending";
        case 1:
          return "Created";
        default:
          return "Unknown";
      }
    },
    scoringFormatScoreType() {
      switch (this.media.scoringTypeID) {
        case 0:
          return "Generic";
        case 1:
          return "Rugby";
        case 2:
          return "Basketball";
        default:
          return "Generic";
      }
    },
  },
  data() {
    return {
      modal: [],
    };
  },
  methods: {
    boolToCheck(v) {
      if (v) {
        return true;
      }
      return 0;
    },
    boolToText(v) {
      if (v) {
        return "Enabled";
      }
      return "Disabled";
    },
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
    dateToLocal: function (date) {
      return moment(date + "+00:00")
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
    },
    dateToUTC: function (date) {
      return moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
    },
    usersInit() {
      this.$store
        .dispatch("getMediaAdminUsers", {
          mediaID: this.mediaID,
        })
        .then(() => {
          console.log("Users loaded");
        });
    },
    usersClickDeleteUser(index) {
      let userID = this.media.admins[index].uuid;
      let email = this.media.admins[index].email;
      let adminsArray = this.media.managementUserUUIDs.split(",");

      this.$swal({
        title: "Are you sure you want to remove " + email + "?",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then((result) => {
        if (result.isConfirmed) {
          // remove userID from adminsArray
          let i = adminsArray.indexOf(userID);
          if (i !== -1) {
            adminsArray.splice(i, 1);
          }
          let formData = new FormData();
          formData.append("managementUserUUIDs", adminsArray.join(","));
          this.$store
            .dispatch("setMediaField", {
              mediaID: this.media.mediaID,
              data: formData,
            })
            .then(
              () => {
                this.$swal({
                  title: "Success!",
                  text: "User '" + email + "' was removed.",
                  icon: "success",
                  confirmButtonText: "Yay!",
                });
              },
              (error) => {
                this.$swal({
                  showCancelButton: true,
                  showConfirmButton: false,
                  title: "Error!",
                  text: error.error,
                  icon: "error",
                  cancelButtonText: "Oh no!",
                });
              }
            );

          // remove local instance
          this.media.admins.splice(index, 1);
        }
      });
    },
    cloudFormatGenericValue(v) {
      if (v) {
        return v;
      }
      return "N/A";
    },
    metricsGetPanelbearURL() {
      let panelbearSiteID = process.env.VUE_APP_PANELBEAR_ID;
      return (
        "<a href='https://app.panelbear.com/sites/" +
        panelbearSiteID +
        "/traffic/?path=%2Fview%2F" +
        this.mediaID +
        "'>Link to view</a>"
      );
    },
    clickModalActivate: function (modal, title, icon) {
      this.modal.title = title;
      this.modal.icon = icon;
      this.modal.inputs = modal;
      this.modal.files = [];
      this.$modal.show("panel-input");
    },
    clickModalClose: function () {
      this.$modal.hideAll();
    },
    clickModalSave: function () {
      let formData = new FormData();

      // process files
      this.modal.files.forEach((f, i) => {
        formData.append("file", f.currentImage);
        formData.append("FILES_" + i + "_S3KEY", f.s3key);
        formData.append("FILES_" + i + "_DBKEY", f.dbkey);
      });

      // process fields
      this.modal.inputs.forEach((i) => {
        if (i.type === "Date") {
          // convert datetime to suitable SQL format
          let value = this.dateToUTC(i.value);
          this.media[i.key] = value;
          formData.append(i.key, value);
        } else if (i.type === "File") {
          console.log("file");
        } else if (i.type === "Check") {
          let value = 0;
          if (i.value === true) {
            value = 1;
          }
          this.media[i.key] = value;
          formData.append(i.key, value);
        } else if (i.type === "AwsParams") {
          this.media[i.key] = i.value;
          formData.append(i.key, i.value);
        } else {
          this.media[i.key] = i.value;
          formData.append(i.key, i.value);
        }
      });

      // commit data
      this.$store
        .dispatch("setMediaField", {
          mediaID: this.media.mediaID,
          data: formData,
        })
        .then(
          (res) => {
            // update local model with S3 response
            res.filekeys.forEach((f) => {
              this.media[f.key] = f.s3url;
            });

            this.$swal({
              title: "Success!",
              text: res.result,
              icon: "success",
              confirmButtonText: "Yay!",
            });
          },
          (error) => {
            this.$swal({
              showCancelButton: true,
              showConfirmButton: false,
              title: "Error!",
              text: error.error,
              icon: "error",
              cancelButtonText: "Oh no!",
            });
          }
        );
      this.clickModalClose();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", "Manage Media");
  },
};
</script>

<style lang="scss" scoped></style>
