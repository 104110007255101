import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// LOGROCKET
import LogRocket from "logrocket";
if (process.env.VUE_APP_ENVIROMENT === "DEV") {
  console.log("Dev environment - Disabling LogRocket");
} else {
  console.log("LogRocket Active");
  LogRocket.init("0gqy1r/anywhere-live");
}

// BOOTSTRAP
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// FONT AWESOME PRO
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fat } from "@fortawesome/pro-thin-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas, far, fal, fat, fad, fab);

// ANIMATE
import "animate.css/animate.css";

// DATATABLES
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

// OTHER LIBRARIES
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import vfmPlugin from "vue-final-modal";
import Notifications from "@kyvg/vue3-notification";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { VAceEditor } from "vue3-ace-editor";
import ace from "ace-builds";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import workerJsonUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-json.js";

// SETUP SSO CONFIG
import Keycloak from "keycloak-js";
let ssoOptions = {
  url: process.env.VUE_APP_SSO_URL,
  realm: process.env.VUE_APP_SSO_REALM,
  clientId: process.env.VUE_APP_SSO_CLIENT,
};

// LOAD PERSISTENT TOKEN
window.$sso = Keycloak(ssoOptions);
const token = localStorage.getItem("sso_token");
const refreshToken = localStorage.getItem("sso_refreshToken");
const idToken = localStorage.getItem("sso_idToken");

// INIT
window.$sso
  .init({
    onLoad: "check-sso",
    silentCheckSsoRedirectUri: window.location.origin + "/sso_check.html",
    checkLoginIframe: false,
    token: token,
    refreshToken: refreshToken,
    idToken: idToken,
  })
  .then(async () => {
    if (window.$sso.authenticated) {
      let sso = window.$sso;
      console.log("Authentication successful");

      if (process.env.VUE_APP_ENVIROMENT !== "DEV") {
        LogRocket.identify(sso.tokenParsed.sub, {
          name: sso.tokenParsed.name,
          email: sso.tokenParsed.email,
        });
      }

      setSsoTokens();

      //Token Refresh
      setInterval(() => {
        sso
          .updateToken(15)
          .then((refreshed) => {
            if (refreshed) {
              setSsoTokens();
            } else {
              //console.log("Token not refreshed");
            }
          })
          .catch(() => {
            if (sso.isTokenExpired()) {
              console.log("Token has expired");
              clearSsoTokens();
            }
          });
      }, 30000);
    } else {
      console.log("Authentication required");
      clearSsoTokens();
    }
  })
  .then(() => {
    renderApp();
  })
  .catch((e) => {
    renderApp();
    console.log("Error: " + e);
  });

function renderApp() {
  const app = createApp(App);
  app.component("fa", FontAwesomeIcon);
  app.component("datatable", Vue3EasyDataTable);
  app.component("VAceEditor", VAceEditor);
  ace.config.setModuleUrl("ace/mode/json_worker", workerJsonUrl);
  app.use(store);
  app.use(router);
  app.use(Notifications);
  app.use(VueSweetalert2);
  app.use(VueLoading);
  app.use(
    vfmPlugin({
      key: "$modal",
      componentName: "modal",
      dynamicContainerName: "ModalsContainer",
    })
  );
  app.mount("#app");
}

function setSsoTokens() {
  let sso = window.$sso;
  localStorage.setItem("sso_token", sso.token);
  localStorage.setItem("sso_refreshToken", sso.refreshToken);
  localStorage.setItem("sso_idToken", sso.idToken);
}

function clearSsoTokens() {
  localStorage.removeItem("sso_token");
  localStorage.removeItem("sso_refreshToken");
  localStorage.removeItem("sso_idToken");
}
