<template>
  <div class="row px-0 animate__animated animate__fadeIn" v-if="countMedia > 0">
    <div class="catalog w-100" :data-catalogid="catalogid">
      <div class="catalog-title">
        <div class="main-title">{{ catalogName }}</div>
        <div class="sub-title">{{ catalogSubtitle }}</div>
      </div>
      <div class="catalog-inner animate__animated animate__fadeInLeft">
        <div
          v-for="(item, index) in orderedMedia"
          :key="index"
          class="catalog-item"
          :data-uuid="item.mediaID"
          @mouseenter="showDescription"
          @mouseleave="hideDescription"
          @click="clickMedia(item)"
        >
          <!-- data-live="streamLive" data-available="streamStarted"-->
          <img
            :class="{
              active: selectedMedia === item.mediaID,
              flipPopup: flipDescription,
            }"
            :src="item.thumbnailURL"
            :alt="item.mediaName"
          />
          <div
            :class="{
              active: selectedMedia === item.mediaID,
              'live-tag': item.isLive,
              'vod-tag': item.isVod,
            }"
            class="thumbnail-tag"
          ></div>
          <div
            class="detail"
            :class="{ active: selectedMedia === item.mediaID }"
          >
            <div
              class="title"
              :class="{ active: selectedMedia === item.mediaID }"
            >
              {{ item.mediaName }}
            </div>
            <div
              class="schedule"
              :class="{
                active: selectedMedia === item.mediaID,
                live: item.isLive,
                soon: item.isStartingSoon,
              }"
            >
              {{ schedule(item) }}
            </div>
          </div>
          <div
            v-html="item.mediaDescription"
            v-if="selectedMedia === item.mediaID"
            class="description"
            :class="{ flipPopup: flipDescription }"
          ></div>
        </div>

        <div class="catalog-padding"></div>
      </div>
      <div
        class="catalog-button left"
        @click="clickLeft"
        v-if="buttonLeftVisible"
      >
        <fa :icon="['fad', 'angle-double-left']"></fa>
      </div>
      <div
        class="catalog-button right"
        @click="clickRight"
        v-if="buttonRightVisible"
      >
        <fa :icon="['fad', 'angle-double-right']"></fa>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

const stepSize = 300;

export default {
  name: "CatalogRow",
  props: [
    "catalogid",
    "catalogName",
    "catalogSubtitle",
    "catalogObjects",
    "sortOrder",
    "showUnpublished",
  ],
  data() {
    return {
      isVisible: false,
      selectedMedia: "",
      flipDescription: false,
      buttonLeftVisible: false,
      buttonRightVisible: true,
    };
  },
  computed: {
    filteredMedia: function () {
      if (!this.showUnpublished) {
        return this.filterPublishedMedia(this.catalogObjects);
      } else {
        return this.catalogObjects;
      }
    },
    countMedia: function () {
      if (
        typeof this.filteredMedia !== "undefined" &&
        this.filteredMedia.length > 0
      ) {
        return this.filteredMedia.length;
      }
      return 0;
    },
    orderedMedia: function () {
      return _.orderBy(this.filteredMedia, "startDateUTC", this.sortOrder);
    },
  },
  mounted() {
    this.recalculateScrollButtons();
    window.addEventListener("resize", this.resizeWindowEvent);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeWindowEvent);
  },
  watch: {},
  methods: {
    filterPublishedMedia(media) {
      if (typeof media !== "undefined") {
        return media.filter((media) => media.isPublished);
      }
    },
    showDescription: function (e) {
      this.selectedMedia = e.target.dataset.uuid;
      if (boundingCheck(e.target).right) {
        this.flipDescription = true;
      }
    },
    hideDescription: function () {
      this.selectedMedia = "";
      this.flipDescription = false;
    },
    clickMedia: function (item) {
      if (item.isLive || item.isVod) {
        this.$router.push("/view/" + item.mediaID);
      }
    },
    clickLeft: function () {
      let container = this.getCatalogContainer(this.catalogid);
      let currentScroll = container.scrollLeft;
      let newScroll;

      if (container.scrollLeft - stepSize < 0) {
        newScroll = 0;
      } else {
        newScroll = currentScroll - stepSize;
      }
      container.scroll({
        left: newScroll,
        behavior: "smooth",
      });

      this.recalculateScrollButtons(newScroll);
    },
    clickRight: function () {
      let container = this.getCatalogContainer(this.catalogid);
      let maxScroll = container.scrollWidth - window.innerWidth;
      let currentScroll = container.scrollLeft;
      let newScroll;

      if (container.scrollLeft + stepSize > maxScroll) {
        newScroll = maxScroll;
        this.buttonRightVisible = false;
      } else {
        newScroll = currentScroll + stepSize;
      }
      container.scroll({
        left: newScroll,
        behavior: "smooth",
      });

      this.recalculateScrollButtons(newScroll);
    },
    resizeWindowEvent() {
      this.recalculateScrollButtons();
    },
    recalculateScrollButtons: function (newVal = null) {
      if (this.countMedia > 0) {
        let container = this.getCatalogContainer(this.catalogid);
        if (typeof container === "object") {
          let maxScroll = container.scrollWidth - window.innerWidth;
          let currentScroll;
          if (newVal === null) {
            currentScroll = container.scrollLeft;
          } else {
            currentScroll = newVal;
          }
          this.buttonLeftVisible = currentScroll > 0;
          this.buttonRightVisible = currentScroll < maxScroll;
        }
      }
    },
    formatDate: function (date, time = true) {
      if (time) {
        return moment(date + "+00:00")
          .local()
          .format("Do MMM YYYY - h:mm a");
      }
      return moment(date + "+00:00")
        .local()
        .format("Do MMM YYYY");
    },
    schedule(item) {
      if (item.isLive) {
        return "LIVE NOW";
      } else if (item.isStartingSoon) {
        return "STARTING SOON";
      } else if (item.isVod) {
        return "Streamed on: " + this.formatDate(item.startDateUTC, false);
      }
      return this.formatDate(item.startDateUTC);
    },
    getCatalogContainer(id) {
      if (this.countMedia > 0) {
        return document
          .querySelector("[data-catalogid='" + id + "']")
          .querySelector(".catalog-inner");
      }
    },
  },
};

function boundingCheck(el) {
  let bounding = el.getBoundingClientRect();

  let bAdj = {};
  bAdj.top = bounding.top;
  bAdj.bottom = bounding.top + 250;
  bAdj.left = bounding.left + bounding.width;
  bAdj.right = bounding.left + bounding.width + 250;

  let bound = {};
  bound.top = bAdj.top < 0;
  bound.left = bAdj.left < 0;
  bound.bottom =
    bAdj.bottom > (window.innerHeight || document.documentElement.clientHeight);
  bound.right =
    bAdj.right > (window.innerWidth || document.documentElement.clientWidth);

  bound.any = bound.top || bound.left || bound.bottom || bound.right;
  bound.all = bound.top && bound.left && bound.bottom && bound.right;

  return bound;
}
</script>

<style lang="scss" scoped></style>
