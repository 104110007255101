<template>
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsTopbar",
  components: {},
  props: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
