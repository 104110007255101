<template>
  <page-title :title="'Anywhere Live' + currentRouteName" />
  <div ref="loadDiv" v-if="!hideFrame"></div>
  <main class="container-fluid px-0">
    <div v-if="!hideFrame" id="nav">
      <Navbar />
    </div>
    <router-view v-if="dataReady" />
    <Footer v-if="!hideFrame" />
    <notifications position="bottom left" />
  </main>
</template>

<script>
// AWL COMPONENTS
import PageTitle from "./components/PageTitle.vue";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    PageTitle,
    Navbar,
    Footer,
  },
  data() {
    return {
      timer: "",
      title: "",
      dataReady: false,
      loader: "",
    };
  },
  computed: {
    ...mapState({
      pageTitle: (state) => state.title,
    }),
    currentRouteName() {
      if (this.pageTitle === "") {
        return "";
      }
      return " | " + this.pageTitle;
    },
    hideFrame() {
      if (typeof this.$route.matched[0] !== "undefined") {
        return !!this.$route.matched[0].props.default.hideFrame;
      }
      return false;
    },
  },
  methods: {
    populateData() {
      this.$store.dispatch("initData").then(() => {
        this.dataReady = true;
        this.loader.hide();
      });
    },
    refreshData() {
      let s = this.$store;
      s.dispatch("RefreshCatalogs");
    },
    cancelRefresh() {
      clearInterval(this.timer);
    },
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.loadDiv,
      canCancel: false,
      lockScroll: true,
      color: "#5a42f5",
    });
    this.populateData();
    // this.timer = setInterval(
    //   function () {
    //     this.refreshData();
    //   }.bind(this),
    //   15000
    // );
  },
  unmounted() {
    this.cancelRefresh();
  },
};
</script>

<style lang="scss">
@import "./styles/awl-dimensions.scss";

// BOOTSTRAP
@import "./styles/overrides/_bootstrap.scss";
@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import "./node_modules/bootstrap/scss/_functions.scss";
@import "./node_modules/bootstrap/scss/_variables.scss";

@import "./styles/common.scss";
</style>
