<template>
  <div></div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["title"],
  methods: {},
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
};
</script>
