<template>
  <pane-input
    panelID="panel-scoring"
    :title="modal.title"
    :icon="modal.icon"
    v-model:modal="modal.inputs"
    v-model:files="modal.files"
    @close="clickModalClose()"
    @save="clickModalSave()"
  />

  <loading-div
    :visible="!score.dataReady"
    icon="asterisk"
    message="Connecting to scoring server..."
  ></loading-div>

  <div v-if="score.dataReady" class="row" id="colControls">
    <div class="col-xl">
      <div class="card mb-3">
        <div
          class="card-body bg-light d-flex justify-content-between align-items-center"
        >
          <button @click="toggleScore" type="button" class="btn btn-primary">
            {{ scoreButtonText }}
          </button>
          <h3>{{ mediaName }}</h3>
          <button @click="resetScore" type="button" class="btn btn-danger">
            Reset Scores
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="card">
            <h5
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span
                class="dot"
                :style="{ background: score.teamAColour }"
              ></span>
              <div class="text-truncate">
                {{ score.teamAName }}
              </div>
              <fa
                @click="editTeam(1)"
                :icon="['fad', 'edit']"
                class="fas fa-edit text-muted"
              ></fa>
            </h5>
            <div class="card-body text-center">
              <h1 class="card-title mb-0">{{ score.teamAScore }}</h1>
            </div>
            <div
              class="card-footer d-flex justify-content-between align-items-center p-2"
            >
              <a
                @click="adjustScore(1, -1)"
                class="btn btn-danger btn-block btn-score-ctrl"
                >- 1</a
              >
              <a
                @click="setManualScore(1)"
                class="btn btn-secondary mx-2 btn-score-ctrl"
                >Manual</a
              >
              <a
                @click="adjustScore(1, 1)"
                class="btn btn-success btn-block btn-score-ctrl"
                >+ 1</a
              >
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card">
            <h5
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span
                class="dot"
                :style="{ background: score.teamBColour }"
              ></span>
              <div class="text-truncate">
                {{ score.teamBName }}
              </div>
              <fa
                @click="editTeam(2)"
                :icon="['fad', 'edit']"
                class="fas fa-edit text-muted"
              ></fa>
            </h5>
            <div class="card-body text-center">
              <h1 class="card-title mb-0">{{ score.teamBScore }}</h1>
            </div>
            <div
              class="card-footer d-flex justify-content-between align-items-center p-2"
            >
              <a
                @click="adjustScore(2, -1)"
                class="btn btn-danger btn-block btn-score-ctrl"
                >- 1</a
              >
              <a
                @click="setManualScore(2)"
                class="btn btn-secondary mx-2 btn-score-ctrl"
                >Manual</a
              >
              <a
                @click="adjustScore(2, 1)"
                class="btn btn-success btn-block btn-score-ctrl"
                >+ 1</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaneInput from "@/components/ConsolePane/PaneInput";

import io from "socket.io-client";
import LoadingDiv from "@/components/LoadingDiv";

export default {
  name: "ScoringGeneric",
  components: {
    LoadingDiv,
    PaneInput,
  },
  props: ["mediaID", "mediaName"],
  computed: {
    messageBrokerURL() {
      return process.env.VUE_APP_BROKER_URL;
    },
    scoreButtonText() {
      if (this.score.scoreVisible) {
        return "Hide Score Bug";
      }
      return "Show Score Bug";
    },
  },
  data() {
    return {
      modal: [],
      socket: null,
      score: {
        scoreVisible: false,
        teamAName: "Team A",
        teamBName: "Team B",
        teamAColour: "#B26CF0",
        teamBColour: "#FFFFFF",
        teamAScore: 0,
        teamBScore: 0,
      },
    };
  },
  mounted() {
    this.score.dataReady = false;

    this.socket = io.connect(this.messageBrokerURL);
    // Handle connection
    this.socket.on("connect", () => {
      this.$notify({
        clean: true,
      });

      // on connect, send channel to "overlay-subscribe" with role to be linked to appropriate controller
      console.log(
        "Connected to broker. Attempting to join session as controller..."
      );
      this.socket.emit("overlay-subscribe", {
        channel: this.mediaID,
        role: "control",
      });
    });

    // Handle incoming messages
    this.socket.on(this.mediaID + "-overlay", (payload) => {
      // Generic message (mostly for testing)
      if (payload.type === "message") {
        console.log("Message: " + payload.msg);
      }

      // Sync messages (received on connection when opened as control role)
      if (payload.type === "sync" || payload.type === "score") {
        this.score.scoreVisible = payload.data.scoreVisible;
        this.score.teamAName = payload.data.teamAName;
        this.score.teamBName = payload.data.teamBName;
        this.score.teamAColour = payload.data.teamAColour;
        this.score.teamBColour = payload.data.teamBColour;
        this.score.teamAScore = payload.data.teamAScore;
        this.score.teamBScore = payload.data.teamBScore;

        // specific actions for SYNC message
        if (payload.type === "sync") {
          this.score.dataReady = true;
          console.log("sync message");
        }
      }
    });

    // Handle disconnect
    this.socket.on("disconnect", () => {
      console.log(" == CONNECTION DROPPED == ");
      this.score.dataReady = false;

      this.$notify({
        title: "Connection Lost",
        text: "The connection to the scoring server has been lost. The system will restart when the connection has been restored.",
        width: "100%",
        type: "error",
        duration: -1,
        position: "top center",
        closeOnClick: false,
      });
    });
  },
  methods: {
    clickModalClose: function () {
      this.$modal.hide("panel-scoring");
    },
    clickModalSave: function () {
      this.modal.inputs.forEach((i) => {
        switch (i.key) {
          case "teamName":
            if (i.team === 1) {
              this.score.teamAName = i.value;
            } else if (i.team === 2) {
              this.score.teamBName = i.value;
            }
            break;
          case "teamColour":
            if (i.team === 1) {
              this.score.teamAColour = i.value;
            } else if (i.team === 2) {
              this.score.teamBColour = i.value;
            }
            break;
          case "manualScore":
            if (i.team === 1) {
              this.score.teamAScore = i.value;
            } else if (i.team === 2) {
              this.score.teamBScore = i.value;
            }
            break;
        }
      });
      this.sendScoringData();
      this.clickModalClose();
    },
    sendScoringData: function () {
      this.socket.emit("overlay-control", {
        channel: this.mediaID,
        role: "control",
        type: "generic",
        data: {
          scoreVisible: this.score.scoreVisible,
          teamAName: this.score.teamAName,
          teamAColour: this.score.teamAColour,
          teamAScore: this.score.teamAScore,
          teamBName: this.score.teamBName,
          teamBColour: this.score.teamBColour,
          teamBScore: this.score.teamBScore,
        },
      });
    },
    toggleScore() {
      this.score.scoreVisible = !this.score.scoreVisible;
      this.sendScoringData();
    },
    resetScore() {
      this.$swal({
        title: "Reset scores?",
        showCancelButton: true,
        confirmButtonText: "Reset",
      }).then((result) => {
        if (result.isConfirmed) {
          this.score.teamAScore = 0;
          this.score.teamBScore = 0;
          this.sendScoringData();
        }
      });
    },
    editTeam(teamNumber) {
      let team = "";
      let nameVal = "";
      let colourVal = "";
      if (teamNumber === 1) {
        team = "A";
        nameVal = this.score.teamAName;
        colourVal = this.score.teamAColour;
      } else {
        team = "B";
        nameVal = this.score.teamBName;
        colourVal = this.score.teamBColour;
      }

      this.modal.title = "Edit Team " + team;
      this.modal.icon = "edit";
      this.modal.inputs = [
        {
          caption: "Team Name",
          type: "Text",
          team: teamNumber,
          key: "teamName",
          value: nameVal,
        },
        {
          caption: "Team Colour",
          type: "Colour",
          team: teamNumber,
          key: "teamColour",
          value: colourVal,
        },
      ];
      this.$modal.show("panel-scoring");
    },
    adjustScore(teamNumber, scoreOffset) {
      if (scoreOffset < 0) {
        if (teamNumber === 1) {
          if (this.score.teamAScore > 0) {
            this.score.teamAScore--;
          }
        } else if (teamNumber === 2) {
          if (this.score.teamBScore > 0) {
            this.score.teamBScore--;
          }
        }
      } else {
        if (teamNumber === 1) {
          this.score.teamAScore++;
        } else if (teamNumber === 2) {
          this.score.teamBScore++;
        }
      }
      this.sendScoringData();
    },
    setManualScore(teamNumber) {
      let team = "";
      let scoreVal = "";
      if (teamNumber === 1) {
        team = "A";
        scoreVal = this.score.teamAScore;
      } else {
        team = "B";
        scoreVal = this.score.teamBScore;
      }

      this.modal.title = "Set Team " + team + " Score";
      this.modal.icon = "whistle";
      this.modal.inputs = [
        {
          caption: "Score",
          type: "Number",
          team: teamNumber,
          key: "manualScore",
          value: scoreVal,
        },
      ];
      this.$modal.show("panel-scoring");
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
