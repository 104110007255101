<template>
  <li
    class="list-group-item d-flex p-0 justify-content-between align-items-center"
  >
    <span class="ps-3" v-html="caption"></span>
    <span
      v-if="value"
      class="text-end pe-3 flex-fill"
      v-html="'&nbsp;' + value"
    ></span>
    <span v-if="glyph" class="text-end pe-3 flex-fill">
      <fa :icon="['fad', glyph]"></fa>
    </span>
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "PaneCardItem",
  components: {},
  props: ["caption", "value", "glyph"],
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
