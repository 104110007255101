<template>
  <li class="nav-item">
    <a
      class="nav-link"
      :class="{ active: isActive, disabled: disabled }"
      :id="'btn' + id + '-tab'"
      data-bs-toggle="tab"
      :href="'#' + id + '-tab'"
      role="tab"
      :aria-controls="id + '-tab'"
      :aria-selected="isActive"
    >
      <fa v-if="icon" :icon="['fad', icon]" class="icon"></fa>
      <span>{{ caption }}</span>
      <span v-if="pill" class="badge rounded-pill bg-secondary ms-1">
        {{ pill }}
      </span>
    </a>
  </li>
</template>

<script>
export default {
  name: "PaneSidebarItem",
  components: {},
  props: ["id", "caption", "icon", "isActive", "disabled", "pill"],
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
