<template>
  <div class="footer-accent fixed-bottom w-100">
    <div class="row">
      <div class="col-12">
        <p class="text-end privacy-text">
          <router-link to="/terms">
            Privacy & Terms of Use
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped></style>
